/*
 * Copyright © 2022 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import type angular from 'angular';
import {AuthService} from '../authentication/service/authService';
import {AppConfigService} from '../common/service/rest/appConfigService';
import {MonatsBelegungService} from '../kind/service/monatsBelegungService';
import {AnwesenheitsSollVerbrauchDirective} from './directive/anwesenheits-soll-verbrauch.directive';
import {MonatsBelegungDirective} from './directive/monats-belegung.directive';
import {GueltigkeitDirective} from './directive/gueltigkeit.directive';
import {LimitedEntityAssignmentControlsDirective} from './directive/limited-entity-assignment-controls.directive';
import {LimitedEntityVerlaufDirective} from './directive/limited-entity-verlauf.directive';
import {FilterListDirective} from './directive/filter-list.directive';

@NgModule({
    imports: [
        CommonModule,
    ],
    declarations: [
        AnwesenheitsSollVerbrauchDirective,
        FilterListDirective,
        GueltigkeitDirective,
        LimitedEntityAssignmentControlsDirective,
        LimitedEntityVerlaufDirective,
        MonatsBelegungDirective,
    ],
    exports: [
        AnwesenheitsSollVerbrauchDirective,
        FilterListDirective,
        GueltigkeitDirective,
        LimitedEntityAssignmentControlsDirective,
        LimitedEntityVerlaufDirective,
        MonatsBelegungDirective,
    ],
    providers: [
        {
            provide: AuthService,
            useFactory: (i: angular.auto.IInjectorService) => i.get<AuthService>('authService'),
            deps: ['$injector'],
        },
        {
            provide: AppConfigService,
            useFactory: (i: angular.auto.IInjectorService) => i.get<AppConfigService>('appConfigService'),
            deps: ['$injector'],
        },
        {
            provide: MonatsBelegungService,
            useFactory: (i: angular.auto.IInjectorService) => i.get<MonatsBelegungService>('monatsBelegungService'),
            deps: ['$injector'],
        },
    ],
})
export class CommonUpgradeModule {
}
