/*
 * Copyright © 2018 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import {ServiceContainer} from '@dv/kitadmin/models';
import type {ApiResponseTransformer, MatrixParams, NoContent, TerminationMode} from '@dv/shared/code';
import {DvbRestUtil, HttpCodes, isRestInclude, PageContainer} from '@dv/shared/code';
import type angular from 'angular';
import type moment from 'moment';

const NO_DATA = 'NO_DATA';
const REQUEST_CANCELLED = 'REQUEST_CANCELLED';

/**
 * Utilities for REST-Calls (angular-<strong>dependent</strong>)
 */
export class DvbRestUtilAngularJS {

    /**
     * @param url REST endpoint for the model
     * @param modelAPI a Model Factory, e.g. Kita
     * @param id
     * @param params an optional parameter object
     */
    public static getModelByIdAndParams<T>(
        url: string,
        modelAPI: ApiResponseTransformer<T>,
        id: string,
        params?: any,
    ): angular.IPromise<T | NoContent> {

        let matrixParams: MatrixParams = {};
        if (params) {
            matrixParams = DvbRestUtil.setGueltigkeitParams({}, params);
            if (isRestInclude(params)) {
                matrixParams.includes = params.includes;
            }
        }

        return DvbRestUtilAngularJS.getModelByUrlAndParams(`${url}/${encodeURIComponent(id)}`,
            modelAPI,
            matrixParams,
            params);
    }

    public static parseHttpRequestConfig(cfg?: angular.IRequestShortcutConfig): angular.IRequestShortcutConfig {
        const config: angular.IRequestShortcutConfig = {};

        if (!cfg) {
            return config;
        }

        if (cfg.timeout) {
            config.timeout = cfg.timeout;
        }

        if (cfg.cache && typeof cfg.cache === 'boolean') {
            config.cache = cfg.cache;
        }

        return config;
    }

    /**
     * @param url REST endpoint for the model
     * @param modelAPI a Model Factory, e.g. Kita
     * @param matrixParams optional matrix parameters.
     * @param config
     */
    public static getModelByUrlAndParams<T>(
        url: string,
        modelAPI: ApiResponseTransformer<T>,
        matrixParams: MatrixParams = {},
        config?: angular.IRequestShortcutConfig,
    ): angular.IPromise<T | NoContent> {

        // If we do not handle NO_CONTENT, the response transformer will silently fail.
        // Better ideas to solve this?

        return ServiceContainer.$http.get(url + DvbRestUtil.encodeMatrixParams(matrixParams), config)
            .then(response => response.status === HttpCodes.NO_CONTENT ?
                null :
                modelAPI.apiResponseTransformer(response.data));
    }

    /**
     * @param url REST endpoint for the model
     * @param modelAPI a Model Factory, e.g. Kita
     * @param matrixParams optional matrix parameters.
     * @param config
     */
    public static getPagedItems<T>(
        url: string,
        modelAPI: ApiResponseTransformer<T>,
        matrixParams: MatrixParams = {},
        config?: angular.IRequestShortcutConfig,
    ): angular.IPromise<PageContainer<T>> {

        return ServiceContainer.$http.get(url + DvbRestUtil.encodeMatrixParams(matrixParams), config)
            .then(response => PageContainer.apiResponseTransformer(modelAPI, response.data));
    }

    public static postAndGetPagedItems<T>(
        url: string,
        data: any,
        modelAPI: ApiResponseTransformer<T>,
        matrixParams: MatrixParams = {},
        config?: angular.IRequestShortcutConfig,
    ): angular.IPromise<PageContainer<T>> {

        return ServiceContainer.$http.post(url + DvbRestUtil.encodeMatrixParams(matrixParams), data, config)
            .then(response => PageContainer.apiResponseTransformer(modelAPI, response.data));
    }

    public static terminate(
        url: string,
        endDate: moment.Moment,
        mode: TerminationMode,
        matrixParams: MatrixParams = {},
    ): angular.IPromise<angular.IHttpResponse<unknown>> {

        DvbRestUtilAngularJS.clearHttpCache();
        matrixParams.mode = mode;
        const data = {date: DvbRestUtil.momentToLocalDate(endDate)};

        return ServiceContainer.$http.put(`${url}${DvbRestUtil.encodeMatrixParams(matrixParams)}`, data);
    }

    /**
     * @param url REST endpoint for the model
     * @param body POST Body data
     * @param modelAPI a Model Factory, e.g. Kita
     * @param matrixParams optional matrix parameters.
     * @param cache TRUE to cache the response, FALSE otherwise (default)
     * @param config
     */
    public static postAndGetModel<T>(
        url: string,
        body: unknown,
        modelAPI: ApiResponseTransformer<T>,
        matrixParams?: MatrixParams,
        cache: boolean = false,
        config: angular.IRequestShortcutConfig = {},
    ): angular.IPromise<T> {

        config.cache = !!cache;

        return ServiceContainer.$http.post(url + DvbRestUtil.encodeMatrixParams(matrixParams ?? {}),
            body,
            config)
            .then(response => modelAPI.apiResponseTransformer(response.data));
    }

    /**
     * @param url REST endpoint for the model
     * @param body PUT Body data
     * @param modelAPI a Model Factory, e.g. Kita
     * @param matrixParams optional matrix parameters.
     * @param cache TRUE to cache the response, FALSE otherwise (default)
     * @param config
     */
    public static putAndGetModel<T>(
        url: string,
        body: unknown,
        modelAPI: ApiResponseTransformer<T>,
        matrixParams?: MatrixParams,
        cache: boolean = false,
        config: angular.IRequestShortcutConfig = {},
    ): angular.IPromise<T> {

        config.cache = !!cache;

        return ServiceContainer.$http.put(url + DvbRestUtil.encodeMatrixParams(matrixParams ?? {}), body, config)
            .then(response => modelAPI.apiResponseTransformer(response.data));
    }

    /**
     * @param url REST endpoint for the model
     * @param body POST Body data
     * @param key the response property holding the response array
     * @param modelAPI a Model Factory, e.g. Kita
     * @param matrixParams optional matrix parameters.
     * @param cache TRUE to cache the response, FALSE otherwise (default)
     * @param config
     */
    public static postAndGetModelsArray<T>(
        url: string,
        body: unknown,
        key: string,
        modelAPI: ApiResponseTransformer<T>,
        matrixParams?: MatrixParams,
        cache: boolean = false,
        config: angular.IRequestShortcutConfig = {},
    ): angular.IPromise<T[]> {

        config.cache = !!cache;

        return ServiceContainer.$http.post<{ [key: string]: T[] }>(
            url + DvbRestUtil.encodeMatrixParams(matrixParams ?? {}),
            body,
            config)
            .then(response => {
                if (!Array.isArray(response.data[key])) {
                    return ServiceContainer.$q.reject(response);
                }

                return response.data[key].map(m => modelAPI.apiResponseTransformer(m));
            });
    }

    /**
     * @param url REST endpoint for the model
     * @param modelAPI a Model Factory, e.g. Kita
     * @param key the response property holding the response array
     * @param matrixParams optional matrix parameters.
     * @param cache TRUE to cache the response, FALSE otherwise (default)
     * @param config
     */
    public static getModelsArray<T>(
        url: string,
        modelAPI: ApiResponseTransformer<T>,
        key: string,
        matrixParams?: MatrixParams,
        cache: boolean = false,
        config?: angular.IRequestShortcutConfig,
    ): angular.IPromise<T[]> {
        // TODO merge cache & config to 1 paramObject
        return DvbRestUtilAngularJS.getModelsArrayWithResponse(url, modelAPI, key, matrixParams, cache, config)
            .then(modelsWithResponse => modelsWithResponse.models);
    }

    /**
     * @param url REST endpoint for the model
     * @param modelAPI a Model Factory, e.g. Kita
     * @param key the response property holding the response array
     * @param matrixParams optional matrix parameters.
     * @param cache TRUE to cache the response, FALSE otherwise (default)
     * @param config
     */
    public static getModelsArrayWithResponse<T>(
        url: string,
        modelAPI: ApiResponseTransformer<T>,
        key: string,
        matrixParams?: MatrixParams,
        cache: boolean = false,
        config?: angular.IRequestShortcutConfig,
    ): angular.IPromise<ResponseWithModels<T>> {

        const requestConfig = config ?? {};
        requestConfig.cache = !!cache;

        return DvbRestUtilAngularJS.getModelsPromise<{ [key: string]: T[] }>(url, matrixParams, requestConfig)
            .then(response => {
                if (Array.isArray(response.data[key])) {
                    const models = response.data[key].map((m: unknown) => modelAPI.apiResponseTransformer(m));

                    return {models, response};
                }

                return ServiceContainer.$q.reject(`After fetching ${url}: Could not find array with key ${key} in response. Payload: ${
                    JSON.stringify(response)}`);
            });
    }

    /**
     * @param url REST endpoint for the model
     * @param modelAPI a Model Factory, e.g. Kita
     * @param key the response property holding the response array
     * @param matrixParams optional matrix parameters.
     * @param cache TRUE to cache the response, FALSE otherwise (default)
     * @param config
     */
    public static getModelsArrayWithCount<T>(
        url: string,
        modelAPI: ApiResponseTransformer<T>,
        key: string,
        matrixParams?: MatrixParams,
        cache: boolean = false,
        config: angular.IRequestShortcutConfig = {},
    ): angular.IPromise<ResponseWithCount<T>> {

        config.cache = !!cache;

        return DvbRestUtilAngularJS.getModelsPromise<Countable & { [key: string]: T[] }>(url, matrixParams, config)
            .then(response => {
                if (!Array.isArray(response.data[key])) {
                    return ServiceContainer.$q.reject(response);
                }

                const models = response.data[key].map(m => modelAPI.apiResponseTransformer(m));

                return {models, count: response.data.count};
            });
    }

    public static clearHttpCache(): void {
        // TODO unschoen: der gesammte HTTP cache wird geloescht. Eigene CacheFactory implementieren?
        const $httpDefaultCache = ServiceContainer.$cacheFactory.get('$http');
        $httpDefaultCache.removeAll();
    }

    public static parseEntityIdFromResponse(response: angular.IHttpResponse<unknown>): string {
        const url = response.headers().location;

        return url.substring(url.lastIndexOf('/') + 1);
    }

    public static cancelRequest(timeout?: angular.IDeferred<unknown>): void {
        if (timeout) {
            timeout.resolve(REQUEST_CANCELLED);
        }
    }

    public static handleRequestCancellation(error: unknown): angular.IPromise<unknown> {
        if (DvbRestUtil.isRequestCancelled(error)) {
            return ServiceContainer.$q.resolve();
        }

        return ServiceContainer.$q.reject(error);
    }

    public static mapIfData<TResponse, TReturn>(
        response: angular.IHttpResponse<TResponse>,
        mapFunction: (val: angular.IHttpResponse<TResponse>) => TReturn,
    ): angular.IPromise<TReturn> {
        if (response.status === HttpCodes.NO_CONTENT) {
            return ServiceContainer.$q.reject(NO_DATA);
        }

        return ServiceContainer.$q.when(mapFunction(response));
    }

    /**
     * @param url REST endpoint for the model
     * @param matrixParams optional matrix parameters.
     * @param config
     */
    private static getModelsPromise<T>(
        url: string,
        matrixParams?: MatrixParams,
        config?: angular.IRequestShortcutConfig,
    ): angular.IHttpPromise<T> {

        return ServiceContainer.$http.get<T>(url + DvbRestUtil.encodeMatrixParams(matrixParams), config);
    }

}

interface ResponseWithModels<T> {
    models: T[];
    response: angular.IHttpResponse<unknown>;
}

interface Countable {
    count: number;
}

interface ResponseWithCount<T> extends Countable {
    models: T[];
}
