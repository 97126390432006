/*
 * Copyright © 2022 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {AuthConfig} from 'angular-oauth2-oidc';

export const IDP_HINT_QUERY_PARAM = 'idp';
export const IDP_HINT_LOCAL_STORAGE = 'kitadmin.idpHint';

export const OIDC_CONFIG: AuthConfig = {
    // URL of the SPA to redirect the user to after login
    redirectUri: `${window.location.origin}/landing`,
    clientId: 'kitAdmin-frontend',
    responseType: 'code',
    // set the scope for the permissions the client should request
    // The first four are defined by OIDC.
    // Important: Request offline_access to get a refresh token
    scope: 'openid',
    showDebugInformation: false,
    clearHashAfterLogin: true,
};
