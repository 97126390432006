/*
 * Copyright © 2019 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {Ng1StateDeclaration} from '@uirouter/angularjs';

export const LOGIN_STATE = {
    name: 'base.login',
    url: '/login',
    template: '<dvb-login></dvb-login>',
    params: {
        toState: {value: null},
        toParams: {value: null},
    },
    data: {
        permission: null,
    },
} satisfies Ng1StateDeclaration;

/**
 * This dummy state is used to navigate to after OIDC login.
 * Since it's a "real" state, the OAuthService will parse the URL - no resolves are backend requests are triggered,
 * such that the auth flow can take it's course with a final navigation to dashboard after login
 */
export const OIDC_LANDING_STATE = {
    name: 'base.oidc-landing',
    url: '/landing',
    data: {
        permission: null,
    },
} satisfies Ng1StateDeclaration;

export const EXPIRED_STATE = {
    name: 'base.expired',
    url: '/expired',
    template: '<h1 class="big-abstand-top" data-translate="ERRORS.ERR_LOGIN_TOKEN_EXPIRED"></h1>',
    data: {
        permission: null,
    },
} satisfies Ng1StateDeclaration;

export const AUTHENTICATION_STATES = {
    LOGIN_STATE,
    OIDC_LANDING_STATE,
    EXPIRED_STATE,
};
