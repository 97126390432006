/*
 * Copyright © 2021 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import {
    BetreuungsfaktorRegel,
    DokumentLayout,
    Firma,
    FirmenKontingent,
    Kind,
    KinderOrtFraktionTransformer,
    KinderOrtTransformer,
    Kontaktperson,
    Mandant,
    OrtTransformer,
    Rechnung,
    Tarif,
} from '@dv/kitadmin/models';
import type {AggragatedEntitySearchTypes, ApiResponseTransformer} from '@dv/shared/code';
import {Benutzer} from '../../benutzer/model/Benutzer';
import {Angestellte} from '../../personal/anstellung/models/Angestellte';
import {Schliesstage} from '../../schliesstage/models/Schliesstage';

export const ENTITY_TRANSFORMER: { [k in AggragatedEntitySearchTypes]: ApiResponseTransformer<any> } = {
    ANGESTELLTE: Angestellte,
    BENUTZER: Benutzer,
    BETREUUNGS_PERSON: KinderOrtFraktionTransformer.create(),
    BETREUUNGSFAKTOR_REGEL: BetreuungsfaktorRegel,
    DOKUMENT_LAYOUT: DokumentLayout,
    FIRMA: Firma,
    FIRMENKONTINGENT: FirmenKontingent,
    GRUPPE: KinderOrtFraktionTransformer.create(),
    KIND: Kind,
    ANMELDUNG_KIND: Kind,
    KINDERORT: KinderOrtTransformer.create(),
    KINDERORT_FRAKTION: KinderOrtFraktionTransformer.create(),
    KITA: KinderOrtTransformer.create(),
    KONTAKTPERSON: Kontaktperson,
    MANDANT: Mandant,
    RECHNUNG: Rechnung,
    SCHLIESSTAGE: Schliesstage,
    TAGES_ELTERN: KinderOrtTransformer.create(),
    TARIF: Tarif,
    TASK_ORT: OrtTransformer.create(),
};
