/*
 * Copyright © 2020 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import {checkPresent, DvbUtil} from '@dv/shared/code';
import type {IDisplayable, IEntityList, IPersistable} from '@dv/shared/code';
import {SchliesstagDateRange} from './SchliesstagDateRange';

export class Schliesstage implements IPersistable, IDisplayable, IEntityList {

    public constructor(
        public id: string | null = null,
        public name: string = '',
        public schliesstagDateRanges: SchliesstagDateRange[] = [],
    ) {
    }

    public static apiResponseTransformer(data: any): Schliesstage {
        const schliesstagDateRanges = Array.isArray(data.schliesstagDateRanges) ?
            data.schliesstagDateRanges.map((s: any) => SchliesstagDateRange.apiResponseTransformer(s)) :
            [];

        return new Schliesstage(data.id, data.name, schliesstagDateRanges);
    }

    public addSchliesstagDateRange(schliesstagDateRange: SchliesstagDateRange): void {
        if (!this.schliesstagDateRanges.includes(schliesstagDateRange)) {
            this.schliesstagDateRanges.push(schliesstagDateRange);
        }
    }

    public removeSchliesstagDateRange(dateRange: SchliesstagDateRange): void {
        DvbUtil.removeFromArray(dateRange, this.schliesstagDateRanges);
    }

    public isValid(): boolean {
        return DvbUtil.isNotEmptyString(this.name) && this.hasValidDateRanges();
    }

    public getDisplayName(): string {
        return this.name;
    }

    public badgeText(): string | undefined {
        return undefined;
    }

    public tooltipText(): string | undefined {
        return undefined;
    }

    public entityId(): string {
        return checkPresent(this.id);
    }

    public toRestObject(): Record<string, unknown> {
        const schliesstagDateRanges: any = this.schliesstagDateRanges.map(dateRange => dateRange.toRestObject());

        return {
            id: this.id,
            name: this.name,
            schliesstagDateRanges,
        };
    }

    private hasValidDateRanges(): boolean {
        let valid = true;
        this.schliesstagDateRanges.forEach(dateRange => {
            if (!dateRange.gueltigAb) {
                valid = false;
            }
        });

        return valid;
    }
}
