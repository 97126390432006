/*
 * Copyright © 2021 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {JaxAnstellung} from '@dv/shared/backend/model/jax-anstellung';
import type {ILimited, IPersistable, IRestModel} from '@dv/shared/code';
import {DvbRestUtil} from '@dv/shared/code';
import type moment from 'moment';
import {AnstellungStandort} from './AnstellungStandort';
import {Ausbildung} from './Ausbildung';

export class Anstellung implements IPersistable, IRestModel<JaxAnstellung>, ILimited {

    public constructor(
        public id: string | null = null,
        public gueltigAb: moment.Moment | null = null,
        public gueltigBis: moment.Moment | null = null,
        public ausbildungen: Ausbildung[] = [],
        public ausbildungIds: string[] = [],
        public anstellungenAb: moment.Moment | null = null,
        public anstellungenBis: moment.Moment | null = null,
        public anstellungStandorte: AnstellungStandort[] = [],
    ) {
    }

    public static apiResponseTransformer(data: any): Anstellung {
        const result = new Anstellung(
            data.id,
            DvbRestUtil.localDateToMoment(data.gueltigAb),
            DvbRestUtil.localDateToMoment(data.gueltigBis),
            data.weeklyHours,
            data.arbeitspensumProzent,
        );

        result.ausbildungen = DvbRestUtil.transformArray(data.ausbildungen, Ausbildung);

        result.anstellungStandorte = DvbRestUtil.transformArray(data.anstellungStandorte, AnstellungStandort);

        if (data.anstellungenAb) {
            result.anstellungenAb = DvbRestUtil.localDateToMoment(data.anstellungenAb);
            result.anstellungenBis = DvbRestUtil.localDateToMoment(data.anstellungenBis);
        }

        return result;
    }

    public toRestObject(): JaxAnstellung {
        return {
            id: this.id ?? undefined,
            gueltigAb: DvbRestUtil.momentToLocalDate(this.gueltigAb) ?? undefined,
            gueltigBis: DvbRestUtil.momentToLocalDate(this.gueltigBis) ?? undefined,
            ausbildungIds: this.ausbildungIds,
            anstellungStandorte: this.anstellungStandorte.map(
                anstellungStandort => anstellungStandort.toRestObject()),
            anstellungenAb: DvbRestUtil.momentToLocalDate(this.anstellungenAb) ?? undefined,
            anstellungenBis: DvbRestUtil.momentToLocalDate(this.anstellungenBis) ?? undefined,
            ausbildungen: this.ausbildungen.map(ausbildung => ausbildung.toRestObject()),
        };
    }

    public isValid(): any {
        return this.anstellungStandorte.every(standort => standort.isValid());
    }
}
