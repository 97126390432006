/*
 * Copyright © 2023 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import {
    ApplicationRole,
    BenutzerType,
    KitaPermission,
    LoginToken,
    Mandant,
    ServiceContainer,
} from '@dv/kitadmin/models';
import type {JaxUpdateBenutzer} from '@dv/shared/backend/model/jax-update-benutzer';
import type {IEntityList, IPersistable, IRestModel, ISearchable, IValidable} from '@dv/shared/code';
import {checkPresent, DvbRestUtil, SearchResultEntry} from '@dv/shared/code';
import type {KitaPermissionType, PersonalPermissionType} from '@dv/shared/roles';
import angular from 'angular';
import type moment from 'moment';
import {Angestellte} from '../../personal/anstellung/models/Angestellte';

export class Benutzer implements IPersistable, IRestModel<JaxUpdateBenutzer>, ISearchable, IEntityList, IValidable {

    public constructor(
        public id: string | null = null,
        public vorName: string | null = null,
        public familienName: string | null = null,
        public username: string | null = null,
        public role: ApplicationRole = new ApplicationRole(),
        public deleted: moment.Moment | null = null,
        public dtype: BenutzerType | null = null,
        public kitaPermissions: KitaPermission[] = [],
        public loginTokens: LoginToken[] = [],
        public angestellte: Angestellte | null = null,
        public angestellteId: string | null = null,
        public mandant: Mandant | null = null,
        public lastLoginTime: moment.Moment | null = null,
    ) {
    }

    public static apiResponseTransformer(data: any): Benutzer {
        const answer = new Benutzer();
        answer.id = data.id;
        answer.vorName = data.vorName;
        answer.familienName = data.familienName;
        answer.username = data.username;
        answer.deleted = DvbRestUtil.localDateTimeToMoment(data.deleted)!;
        answer.dtype = data.dtype;
        answer.role = ApplicationRole.apiResponseTransformer(data.role);
        answer.angestellteId = data.angestellteId;

        if (Array.isArray(data.kitaPermissions)) {
            answer.kitaPermissions = data.kitaPermissions
                .map((kitaPermission: any) => KitaPermission.apiResponseTransformer(kitaPermission));
        }

        if (Array.isArray(data.loginTokens)) {
            answer.loginTokens = data.loginTokens.map((access: any) => LoginToken.apiResponseTransformer(access));
        }

        if (data.angestellte) {
            answer.angestellte = Angestellte.apiResponseTransformer(data.angestellte);
        }

        if (data.mandant) {
            answer.mandant = Mandant.apiResponseTransformer(data.mandant);
        }

        if (data.lastLoginTime) {
            answer.lastLoginTime = DvbRestUtil.localDateTimeToMoment(data.lastLoginTime);
        }

        return answer;
    }

    public toSearchResultEntry(): SearchResultEntry {
        return new SearchResultEntry('BENUTZER', this.id!, this.getDisplayName(), this.badgeText());
    }

    public entityId(): string {
        return checkPresent(this.id);
    }

    public badgeText(): string | undefined {
        if (this.role.userRole) {
            return ServiceContainer.$translate.instant(`BENUTZER.${this.role.userRole}`);
        }

        return this.getPermissionString(this.role.kitaPermission, this.role.personalPermission);
    }

    public tooltipText(): string | undefined {
        const permissions = this.kitaPermissions
            .map(kp => `${kp.kitaName}: ${this.getPermissionString(kp.kitaPermission, kp.personalPermission)}`);

        if (permissions.length === 0) {
            return ServiceContainer.$translate.instant('BENUTZER.FUER_ALLE_KINDER_ORTE');
        }

        return permissions.join('\n');
    }

    public getDisplayName(): string {
        return `${this.familienName} ${this.vorName}`;
    }

    public isDeleted(): boolean {
        return this.deleted !== null;
    }

    public toRestObject(newPlainTextPassword?: string | null): JaxUpdateBenutzer {
        return {
            vorName: checkPresent(this.vorName),
            familienName: checkPresent(this.familienName),
            username: checkPresent(this.username),
            role: this.role.toRestObject(),
            password: newPlainTextPassword ?? undefined,
        };
    }

    public hasVorName(): boolean {
        return angular.isString(this.vorName) && this.vorName.length > 0;
    }

    public hasFamilienName(): boolean {
        return angular.isString(this.familienName) && this.familienName.length > 0;
    }

    public hasUsername(): boolean {
        return angular.isString(this.username) && this.username.length > 0;
    }

    public isValid(): boolean {
        return this.hasVorName() && this.hasFamilienName() && this.hasUsername();
    }

    public isOidcUser(): boolean {
        return this.dtype === BenutzerType.OIDC_USER;
    }

    private getPermissionString(
        kitaPermission: KitaPermissionType | null,
        personalPermission: PersonalPermissionType | null,
    ): string {
        const $translate = ServiceContainer.$translate;

        const result: string[] = [];
        if (kitaPermission) {
            result.push($translate.instant(`BENUTZER.KITA_PERMISSION.${kitaPermission}`));
        }
        if (personalPermission) {
            result.push($translate.instant(`BENUTZER.PERSONAL_PERMISSION.${personalPermission}`));
        }

        return result.join(', ');
    }

}
