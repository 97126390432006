/*
 * Copyright © 2022 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import {isPresent, DvbRestUtil} from '@dv/shared/code';
import moment from 'moment';
import type {ILimited, IPersistable, IRestModel} from '@dv/shared/code';

export class AnwesenheitsSoll implements IPersistable, IRestModel, ILimited {

    public constructor(
        public id: string | null = null,
        public gueltigAb: moment.Moment | null = null,
        public gueltigBis: moment.Moment | null = null,
        public tage: number | null = null,
        public halbtage: number | null = null,
        public fruehbetreuungen: number | null = null,
        public mittage: number | null = null,
        public halbeNachmittage: number | null = null,
        public bemerkung: string | null = null,
    ) {
    }

    public static apiResponseTransformer(data: any): AnwesenheitsSoll {
        return new AnwesenheitsSoll(
            data.id,
            DvbRestUtil.localDateToMoment(data.gueltigAb),
            DvbRestUtil.localDateToMoment(data.gueltigBis),
            data.tage,
            data.halbtage,
            data.fruehbetreuungen,
            data.mittage,
            data.halbeNachmittage,
            data.bemerkung,
        );
    }

    public isValid(): boolean {
        return isPresent(this.gueltigAb)
            && isPresent(this.gueltigBis)
            && this.gueltigAb.isSame(this.gueltigBis, 'year');
    }

    public copy(): AnwesenheitsSoll {
        return new AnwesenheitsSoll(
            this.id,
            moment(this.gueltigAb),
            moment(this.gueltigBis),
            this.tage,
            this.halbtage,
            this.fruehbetreuungen,
            this.mittage,
            this.halbeNachmittage,
            this.bemerkung,
        );
    }

    public toRestObject(): Record<string, unknown> {
        return {
            id: this.id,
            gueltigAb: DvbRestUtil.momentToLocalDate(this.gueltigAb),
            gueltigBis: DvbRestUtil.momentToLocalDate(this.gueltigBis),
            tage: this.tage,
            halbtage: this.halbtage,
            fruehbetreuungen: this.fruehbetreuungen,
            mittage: this.mittage,
            halbeNachmittage: this.halbeNachmittage,
            bemerkung: this.bemerkung,
        };
    }
}
