/*
 * Copyright © 2021 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {IEquals} from '@dv/shared/code';

export class MinMaxFilterProperty implements IEquals<MinMaxFilterProperty> {

    public min: number;
    public max: number;

    public constructor(
        min: number | null = Number.NEGATIVE_INFINITY,
        max: number | null = Number.POSITIVE_INFINITY,
    ) {
        this.min = min ?? Number.NEGATIVE_INFINITY;
        this.max = max ?? Number.POSITIVE_INFINITY;
    }

    public static copy(other: MinMaxFilterProperty): MinMaxFilterProperty {
        return new MinMaxFilterProperty(other.min, other.max);
    }

    public equals(other: MinMaxFilterProperty | null): boolean {
        if (!other) {
            return false;
        }

        return this.min === other.min && this.max === other.max;
    }
}
