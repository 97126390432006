/*
 * Copyright © 2023 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import {Directive, ElementRef, Injector, Input} from '@angular/core';
import {UpgradeComponent} from '@angular/upgrade/static';
import {AnwesenheitsSollVerbrauch} from '../../kind/anwesenheitssoll/model/AnwesenheitsSollVerbrauch';

@Directive({
    selector: 'dv-anwesenheits-soll-verbrauch',
})
export class AnwesenheitsSollVerbrauchDirective extends UpgradeComponent {

    @Input({required: true}) public anwesenheitsSollVerbrauch!: AnwesenheitsSollVerbrauch;

    public constructor(elementRef: ElementRef, injector: Injector) {
        super('dvbAnwesenheitsSollVerbrauch', elementRef, injector);
    }
}
