export class AbstractExpander {
  constructor(encoder) {
    this.encoder = encoder;
  }
  encodeName(value) {
    return this.encoder.encodeName(value);
  }
  encodeValue(value) {
    return this.encoder.encodeValue(value);
  }
  isPlain(value) {
    return typeof value === 'string' || typeof value === 'number' || typeof value === 'boolean';
  }
  flattenObjectEntries(obj) {
    const result = Object.keys(obj).map(key => [String(key), String(obj[key])]).reduce((prev, curr) => [...prev, ...curr], []);
    return result;
  }
  flattenArray(escapedPrefix, arr, delimiter) {
    const result = arr.map(value => `${escapedPrefix}${this.encodeValue(value)}`).join(delimiter);
    return result;
  }
  flattenObjectExploded(obj, prefix, assignment, delimiter) {
    const result = Object.keys(obj).map(key => `${prefix}${this.encodeValue(key)}${assignment}${this.encodeValue(obj[key])}`).join(delimiter);
    return result;
  }
  isEmpty(value) {
    if (value === '') {
      return true;
    }
    if (Array.isArray(value)) {
      return !value.length;
    }
    if (typeof value === 'object' && value !== null) {
      for (const key in value) {
        if (Object.prototype.hasOwnProperty.call(value, key)) {
          return false;
        }
      }
      return true;
    }
    return false;
  }
}
