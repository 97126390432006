/*
 * Copyright © 2018 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {
    AnwesenheitsZeit,
    BetreuungsZeitraumBelegung,
    KinderOrtFraktion,
    KinderOrtFraktionId,
} from '@dv/kitadmin/models';
import {PlatzTypen} from '@dv/kitadmin/models';
import type {BelegungsZustand} from '@dv/shared/code';
import {checkPresent, DvbDateUtil} from '@dv/shared/code';
import moment from 'moment';
import type {BewerbungStrategy} from '../../../kinderort/component/dvb-kita-kinder/BewerbungStrategy';
import type {SchliesstagDateRange} from '../../../schliesstage/models/SchliesstagDateRange';

export class MonatsBelegungZeitInput {

    public showInput: boolean = false;
    public hasCustomFieldValues: boolean = false;
    public isSchliesstag: boolean = false;

    public freiePlaetze: string | number = '';
    public freiePlaetzeTitle: string = '';

    public constructor(
        private bewerbungStrategy: BewerbungStrategy,
        public anwesenheit: AnwesenheitsZeit,
        public selectedGruppe: KinderOrtFraktion,
        public availableGruppen: KinderOrtFraktion[],
        private schliesstagDateRangesByKinderOrtId: Map<string, SchliesstagDateRange[]>
            = new Map<string, SchliesstagDateRange[]>(),
        public allowedEditVon: moment.Moment | null = null,
        public allowedEditBis: moment.Moment | null = null,
        public betreuungsZeitraumBelegungByGruppeId: Map<KinderOrtFraktionId, BetreuungsZeitraumBelegung>
            = new Map<KinderOrtFraktionId, BetreuungsZeitraumBelegung>(),
        public belegungsZustand?: BelegungsZustand,
        public platzTyp: PlatzTypen = PlatzTypen.createPrivat(),
        public rowSpan: number = 1,
    ) {
    }

    public changeGruppe(fraktion: KinderOrtFraktion): void {
        if (this.selectedGruppe === fraktion) {
            return;
        }

        this.selectedGruppe = fraktion;
        this.init();
    }

    public setTimesFromBetreuungsZeitraum(): void {
        const betreuungsZeitraum = this.anwesenheit.betreuungsZeitraum!;
        this.anwesenheit.von = moment(betreuungsZeitraum.von);
        this.anwesenheit.bis = moment(betreuungsZeitraum.bis);
    }

    public hasValidZeit(): boolean {
        if (this.anwesenheit.von && this.anwesenheit.bis) {
            // when both are set, we must have a valid BetreuungsZeitraumBelegung
            const id = checkPresent(this.selectedGruppe.id);

            return this.betreuungsZeitraumBelegungByGruppeId.has(id);
        }

        return !(this.anwesenheit.von ?? this.anwesenheit.bis);
    }

    public init(): void {
        this.showInput = this.hasPlaetze(this.selectedGruppe.id!);
        this.initHasCustomFieldValues();
        this.initIsSchliesstag();

        if (this.showInput) {
            const id = checkPresent(this.selectedGruppe.id);
            const bzb = checkPresent(this.betreuungsZeitraumBelegungByGruppeId.get(id));
            this.freiePlaetze = this.bewerbungStrategy.getZeitraumFeldValue(bzb);
            this.freiePlaetzeTitle = this.bewerbungStrategy.getZeitraumFeldTitle(bzb);
            this.rowSpan = this.anwesenheit.customFieldValues.length > 0 && !this.isSchliesstag ? 2 : 1;
        } else {
            this.freiePlaetze = '';
            this.freiePlaetzeTitle = '';
            this.rowSpan = 1;
        }
    }

    public initHasCustomFieldValues(): void {
        this.hasCustomFieldValues = this.anwesenheit.customFieldValues.some(element => element.value.value);
    }

    private hasPlaetze(gruppeId: string): boolean {
        const betreuungsZeitraumBelegung = this.betreuungsZeitraumBelegungByGruppeId.get(gruppeId);

        if (betreuungsZeitraumBelegung) {
            return betreuungsZeitraumBelegung.anzahlPlaetze !== null;
        }

        return false;
    }

    private initIsSchliesstag(): void {
        const schliesstagDateRanges = this.schliesstagDateRangesByKinderOrtId
            .get(checkPresent(this.selectedGruppe.kinderOrtId));

        if (!schliesstagDateRanges && !this.allowedEditVon && !this.allowedEditBis) {
            return;
        }

        this.isSchliesstag = !!schliesstagDateRanges?.some(value =>
            DvbDateUtil.isGueltigOn(value, checkPresent(this.anwesenheit.datum)));
        this.isSchliesstag ||= this.editForbidden();

        if (this.isSchliesstag) {
            this.anwesenheit.von = null;
            this.anwesenheit.bis = null;
        }
    }

    private editForbidden(): boolean {
        return !!this.allowedEditVon?.isAfter(this.anwesenheit.datum)
            || !!this.allowedEditBis?.isBefore(this.anwesenheit.datum);
    }
}
