/*
 * Copyright © 2019 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {Belegung, CustomFieldValue, VertraglichesPensum} from '@dv/kitadmin/models';
import {PlatzTypen} from '@dv/kitadmin/models';
import {JaxBetreuungInFerienzeit} from '@dv/shared/backend/model/jax-betreuung-in-ferienzeit';
import type {BelegungsZustand} from '@dv/shared/code';
import {
    checkPresent,
    DvbUtil,
    Gueltigkeit,
    KGB,
    KindergartenBelegung,
    KindergartenType,
    SearchResultEntry,
} from '@dv/shared/code';
import type {AngestellteService} from '../../../personal/anstellung/service/angestellteService';

type KindergartenLocation = 'intern' | 'extern';

export class ZuweisenFormModel {

    public belegungsZustand: BelegungsZustand | null = null;

    public betreuungsfaktor: number | null = null;
    public hasBetreuungsfaktor: boolean = false;

    public kindergartenBelegung: KindergartenBelegung = KindergartenBelegung.KEINE;
    public betreuungInFerienzeit: JaxBetreuungInFerienzeit = JaxBetreuungInFerienzeit.KEINE_ANGABE;
    public hasKindergarten: boolean = false;
    public kgType?: KindergartenType;
    public kgLocation?: KindergartenLocation;
    public schulStufe: number | null = null;
    public eingewoehnungPhase: boolean = false;
    public eingewoehnungRange: Gueltigkeit = new Gueltigkeit();

    public bemerkung: string | null = null;
    public noFlexiblePlaetze: boolean | null = null;

    public standardPlatzTypen: PlatzTypen = PlatzTypen.createPrivat();
    public standardVertraglichesPensum: number | null = null;

    public customFieldValues: CustomFieldValue[] = [];

    public vertraglichePensen: { [fraktionId: string]: VertraglichesPensum[] } = {};

    public bezugsPersonen: SearchResultEntry[] = [];

    public static from(belegung: Belegung, angestellteService: AngestellteService): ZuweisenFormModel {
        const model = new ZuweisenFormModel();

        model.betreuungsfaktor = belegung.betreuungsfaktor;
        model.hasBetreuungsfaktor = !!belegung.betreuungsfaktor;

        model.kindergartenBelegung = belegung.kindergartenBelegung;
        model.betreuungInFerienzeit = belegung.betreuungInFerienzeit;
        ZuweisenFormModel.updateKindergarten(model);
        model.schulStufe = belegung.schulStufe;

        model.bemerkung = belegung.bemerkung;
        model.noFlexiblePlaetze = belegung.noFlexiblePlaetze;

        model.customFieldValues = belegung.customFieldValues;
        model.bezugsPersonen = [];
        model.eingewoehnungPhase = belegung.eingewoehnungPhase ?? false;
        model.eingewoehnungRange = ZuweisenFormModel.getEingewoehnungRange(belegung);

        if (DvbUtil.isNotEmptyArray(belegung.bezugsPersonIds)) {
            const config = {cache: true};
            belegung.bezugsPersonIds.forEach(id => {
                angestellteService.getAsDisplayable(id, config)
                    .then(d => new SearchResultEntry('ANGESTELLTE', d.id, d.getDisplayName()))
                    .then(e => model.bezugsPersonen.push(e));
            });
        }

        belegung.gruppenBelegungen.forEach(gb => {
            model.vertraglichePensen[gb.gruppeId!] = gb.vertraglichePensen;
        });

        return model;
    }

    private static updateKindergarten(model: ZuweisenFormModel): void {
        model.kgType = ZuweisenFormModel.getKgType(model.kindergartenBelegung);
        model.kgLocation = ZuweisenFormModel.getKgLocation(model.kindergartenBelegung);
        model.hasKindergarten = model.kindergartenBelegung !== KindergartenBelegung.KEINE;
    }

    private static getKgType(kindergartenBelegung: KindergartenBelegung): KindergartenType | undefined {
        if (KGB.isKG1(kindergartenBelegung)) {
            return KindergartenType.KG_1;
        }
        if (KGB.isKG2(kindergartenBelegung)) {
            return KindergartenType.KG_2;
        }
        if (KGB.isSchule(kindergartenBelegung)) {
            return KindergartenType.SCHULE;
        }

        return undefined;
    }

    private static getKgLocation(kindergartenBelegung: KindergartenBelegung): KindergartenLocation | undefined {
        if (KGB.isInternal(kindergartenBelegung)) {
            return 'intern';
        }
        if (KGB.isExternal(kindergartenBelegung)) {
            return 'extern';
        }

        return undefined;
    }

    private static getEingewoehnungRange(belegung: Belegung): Gueltigkeit {
        return belegung.eingewoehnungPhase ?
            Gueltigkeit.copy(new Gueltigkeit(belegung.eingewoehnungVon, belegung.eingewoehnungBis))! :
            new Gueltigkeit();
    }

    /**
     * sets properties belegungsZustand, betreuungsfaktor, kindergartenBelegung, bemerkung,
     * bemerkungInBetreuungsvereinbarung, noFlexiblePlaetze and eingewoehnungPhase with von-bis dates.
     */
    public applyToBelegung(belegung: Belegung): void {
        belegung.belegungsZustand = this.belegungsZustand;

        belegung.betreuungsfaktor = this.hasBetreuungsfaktor ? this.betreuungsfaktor : null;

        belegung.kindergartenBelegung = this.hasKindergarten ?
            this.determineKinderGarten() :
            KindergartenBelegung.KEINE;
        belegung.betreuungInFerienzeit = this.hasKindergarten ?
            this.betreuungInFerienzeit :
            JaxBetreuungInFerienzeit.KEINE_ANGABE;
        belegung.schulStufe = this.hasKindergarten ? this.schulStufe : null;

        belegung.bemerkung = this.bemerkung;
        belegung.bemerkungInBetreuungsvereinbarung = DvbUtil.isNotEmptyString(this.bemerkung);
        belegung.noFlexiblePlaetze = this.noFlexiblePlaetze;
        belegung.bezugsPersonIds = this.bezugsPersonen.map(searchResult => searchResult.id);
        belegung.eingewoehnungPhase = this.eingewoehnungPhase;
        Object.assign(belegung, this.getEingewoehnungRange());
    }

    private getEingewoehnungRange(): {
        eingewoehnungVon: moment.Moment | null;
        eingewoehnungBis: moment.Moment | null;
    } {
        return {
            eingewoehnungVon: this.eingewoehnungPhase ? checkPresent(this.eingewoehnungRange?.gueltigAb) : null,
            eingewoehnungBis: this.eingewoehnungPhase ? checkPresent(this.eingewoehnungRange?.gueltigBis) : null,
        };
    }

    private determineKinderGarten(): KindergartenBelegung {
        const belegung = KindergartenBelegung.KEINE;
        const isIntern = this.kgLocation === 'intern';
        const isExtern = this.kgLocation === 'extern';

        if (this.kgType === KindergartenType.KG_1) {
            if (isIntern) {
                return KindergartenBelegung.KG1_INT;
            }
            if (isExtern) {
                return KindergartenBelegung.KG1_EXT;
            }
        }
        if (this.kgType === KindergartenType.KG_2) {
            if (isIntern) {
                return KindergartenBelegung.KG2_INT;
            }
            if (isExtern) {
                return KindergartenBelegung.KG2_EXT;
            }
        }
        if (this.kgType === KindergartenType.SCHULE) {
            if (isIntern) {
                return KindergartenBelegung.SCHULE_INT;
            }
            if (isExtern) {
                return KindergartenBelegung.SCHULE_EXT;
            }
        }

        return belegung;
    }
}
