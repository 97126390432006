import { RESERVED_CHARACTERS } from '../encoder';
export class URITeplateCompatibleEncoder {
  constructor(opts) {
    var _a;
    this.opts = {
      allowReserved: (_a = opts === null || opts === void 0 ? void 0 : opts.allowReserved) !== null && _a !== void 0 ? _a : false
    };
  }
  encodeName(key) {
    return this.encodeValue(key);
  }
  encodeValue(value) {
    if (this.opts.allowReserved) {
      return String(value);
    }
    return this.percentEscapeReservedCharacters(String(value));
  }
  percentEscapeReservedCharacters(text) {
    const result = Array.from(text).map(c => this.escapeReservedChar(c)).join('');
    return result;
  }
  escapeReservedChar(c) {
    if (URITeplateCompatibleEncoder.RESERVED_CHARACTERS.has(c)) {
      return '%' + c.charCodeAt(0).toString(16).toUpperCase();
    }
    return c;
  }
}
URITeplateCompatibleEncoder.RESERVED_CHARACTERS = new Set(RESERVED_CHARACTERS);
