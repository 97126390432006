/*
 * Copyright © 2023 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import {Directive, ElementRef, EventEmitter, Injector, Input, Output} from '@angular/core';
import {UpgradeComponent} from '@angular/upgrade/static';
import {KinderOrt} from '@dv/kitadmin/models';
import moment from 'moment';
import type {MonatsBelegungInputRow} from '../../kind/zuweisung/dvb-monats-belegung/MonatsBelegungInputRow';

@Directive({
    selector: 'dv-monats-belegung',
})
export class MonatsBelegungDirective extends UpgradeComponent {

    @Input({required: true}) public kita!: KinderOrt;
    @Input({required: true}) public zuweisungAb!: moment.Moment;
    @Input({required: true}) public inputRows!: MonatsBelegungInputRow[];
    @Input() public parentView?: boolean;
    @Input() public readonly?: boolean;

    @Output() public readonly onEmptyAnwesenheitsZeiten!: EventEmitter<void>;

    public constructor(elementRef: ElementRef, injector: Injector) {
        super('dvbMonatsBelegung', elementRef, injector);
    }
}
