/*
 * Copyright © 2018 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {BetreuungsZeitraumBelegung, Kind, Kontingente} from '@dv/kitadmin/models';
import {ServiceContainer} from '@dv/kitadmin/models';
import type {RestInclude} from '@dv/shared/code';
import {checkPresent} from '@dv/shared/code';
import type angular from 'angular';
import moment from 'moment';
import type {KinderOrtService} from '../../../common/service/rest/kinderort/kinderOrtService';
import {KindFilterModel} from '../../service/kinderFilter-models/KindFilterModel';
import type {KinderListeStrategy} from './KinderListeStrategy';

const roundingMultiple = 100;

export class BewerbungStrategy implements KinderListeStrategy {

    private kinderLoaded: boolean = false;
    private kindFilterModelInitialized: boolean = false;

    public constructor(
        public isBewerbung: boolean = true,
        public isBelegung: boolean = false,
        public gruppenHeaderKey: string = 'KINDERORT.TITLE_FREIE_KAPAZITAET',
        public listModel: string = 'bewerbung',
        public pensumDisplay: string = '0%',
        public capacityOnly: boolean = false,
    ) {
    }

    public static calcFreiePlaetze(betreuungsZeitraumBelegung: BetreuungsZeitraumBelegung): number {

        const anzahl = checkPresent(betreuungsZeitraumBelegung.anzahlPlaetze);
        const belegt = checkPresent(betreuungsZeitraumBelegung.belegtePlaetze);

        return Math.round(roundingMultiple * (anzahl - belegt)) / roundingMultiple;
    }

    public orderBy(kind: Kind): (string | number | boolean)[] {
        const anmeldedatumUnix = kind.anmeldeDatum ? kind.anmeldeDatum.unix() : 0;
        const bewerbung = checkPresent(kind.bewerbung);

        return [
            !bewerbung.gewuenschteBetreuungAb,
            bewerbung.prioritaet,
            -anmeldedatumUnix,
            kind.familienName ?? '',
            kind.vorName,
        ];
    }

    public getZeitraumFeldTitle(betreuungsZeitraumBelegung: BetreuungsZeitraumBelegung): string {
        const keinePlaetzeValue = ServiceContainer.$translate.instant('COMMON.KAPAZITAET_KEINE_PLAETZE');
        const plaetzeValue = betreuungsZeitraumBelegung.anzahlPlaetze ?? keinePlaetzeValue;
        const maxPlaetzeValue = betreuungsZeitraumBelegung.maxAnzahlPlaetze ?? keinePlaetzeValue;

        if (this.capacityOnly) {
            return ServiceContainer.$translate.instant('KINDERORT.ZEITRAUMFELD_TITLE_FREI_CAPACITY', {
                plaetze: plaetzeValue,
                maxPlaetze: maxPlaetzeValue,
            });
        }

        const freiePlaetzeValue = betreuungsZeitraumBelegung.anzahlPlaetze === null ?
            keinePlaetzeValue :
            BewerbungStrategy.calcFreiePlaetze(betreuungsZeitraumBelegung);
        const kinderValue = betreuungsZeitraumBelegung.anzahlKinder ?? 0;

        if (moment.isMoment(betreuungsZeitraumBelegung.verfuegbarBis)) {
            return ServiceContainer.$translate.instant('KINDERORT.ZEITRAUMFELD_TITLE_FREI_LIMITED', {
                plaetze: plaetzeValue,
                maxPlaetze: maxPlaetzeValue,
                freiePlaetze: freiePlaetzeValue,
                kinder: kinderValue,
                verfuegbarBis: betreuungsZeitraumBelegung.verfuegbarBis.format('D.M.YYYY'),
            });
        }

        return ServiceContainer.$translate.instant('KINDERORT.ZEITRAUMFELD_TITLE_FREI', {
            plaetze: plaetzeValue,
            maxPlaetze: maxPlaetzeValue,
            freiePlaetze: freiePlaetzeValue,
            kinder: kinderValue,
        });
    }

    public getZeitraumFeldValue(betreuungsZeitraumBelegung: BetreuungsZeitraumBelegung): string {
        if (betreuungsZeitraumBelegung.anzahlPlaetze === null) {
            return '';
        }
        if (this.capacityOnly) {
            // not empty string, to avoid 'no-value' crossed out background
            return ' ';
        }

        return String(BewerbungStrategy.calcFreiePlaetze(betreuungsZeitraumBelegung));
    }

    public createKindFilterModel(params: {
        kind: Kind;
        kitaKontingente: Kontingente[];
        gruppenIds: string[];
        firstOfWeek: moment.Moment;
    }): angular.IPromise<KindFilterModel> {

        const kind = params.kind;

        return KindFilterModel.createFromBewerbung(
            checkPresent(kind.bewerbung),
            params.kitaKontingente,
            params.gruppenIds,
            params.firstOfWeek,
            kind,
            kind.belegungen,
        ).then(kindFilterModel => {
            this.kindFilterModelInitialized = true;

            return kindFilterModel;
        });
    }

    public loadKinder(
        kinderOrtService: KinderOrtService,
        params: RestInclude & angular.IRequestShortcutConfig & { kitaId: string },
    ): angular.IPromise<Kind[]> {

        return kinderOrtService.getKinderWithBewerbungen(params.kitaId, params)
            .then(kinder => {
                this.kinderLoaded = true;

                return kinder;
            });
    }

    public isLoaded(): boolean {
        return this.kinderLoaded && this.kindFilterModelInitialized;
    }

    public updatePensumTotal(kinder: Kind[]): void {
        let pensumTotalMin = 0;
        let pensumTotalMax = 0;

        kinder.forEach(kind => {
            if (!(kind as any).visible) {
                return;
            }
            const bewerbung = checkPresent(kind.bewerbung);
            const pensum = checkPresent(bewerbung.pensum);
            pensumTotalMin += checkPresent(pensum.von);
            pensumTotalMax += checkPresent(pensum.bis);
        });

        const roundedPensumTotalMin = Math.round(pensumTotalMin * roundingMultiple) / roundingMultiple;
        const roundedPensumTotalmax = Math.round(pensumTotalMax * roundingMultiple) / roundingMultiple;
        this.pensumDisplay = `${roundedPensumTotalMin}% - ${roundedPensumTotalmax}%`;
    }
}
