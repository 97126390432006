import { MatrixParamExpander } from './matrix-param-expander';
import { LabelParamExpander } from './label-param-expander';
import { FormParamExpander } from './form-param-expander';
import { SimpleParamExpander } from './simple-param-expander';
import { URIComponentEncoder } from '../encoders/uri-component-encoder';
export class MultiStyleExpander {
  constructor(encoder = new URIComponentEncoder(), expanders = {
    matrix: new MatrixParamExpander(encoder),
    label: new LabelParamExpander(encoder),
    form: new FormParamExpander(encoder),
    simple: new SimpleParamExpander(encoder),
    spaceDelimited: new SimpleParamExpander(encoder),
    pipeDelimited: new SimpleParamExpander(encoder),
    deepObject: new SimpleParamExpander(encoder)
  }) {
    this.encoder = encoder;
    this.expanders = expanders;
    // nop
  }
  withExpander(style, encoder) {
    return new MultiStyleExpander(this.encoder, Object.assign(Object.assign({}, this.expanders), {
      [style]: encoder
    }));
  }
  expandParameter(param, style) {
    const expander = this.expanders[style];
    if (!expander) {
      throw Error(`No expander found implemented: ${style}`);
    }
    const result = expander.expand(param);
    return result;
  }
}
