/*
 * Copyright © 2023 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {EnvironmentProviders} from '@angular/core';
import {makeEnvironmentProviders} from '@angular/core';
import {MultiStyleExpander} from '@honoluluhenk/http-param-expander';
import type {Param} from './generated';
import {Configuration} from './generated';

export const REST_BACKEND = '/kitadmin';

function isEmptyMatrixParam(param: Param): boolean {
    return param.style === 'matrix'
            && !!param.value
            && typeof param.value === 'object'
            && Object.keys(param.value).length === 0;
}

export function withBackendApiConfiguration(): Configuration {
    const expander = new MultiStyleExpander();

    return new Configuration({
        basePath: REST_BACKEND,
        encodeParam: param => {
            if (isEmptyMatrixParam(param)) {
                return '';
            }

            return expander.expandParameter(param, param.style);
        },
    });
}

export function provideApi(withConfiguration: () => Configuration = withBackendApiConfiguration): EnvironmentProviders {
    return makeEnvironmentProviders([
        {provide: Configuration, useFactory: withConfiguration},
    ]);
}
