/*
 * Copyright © 2021 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {JaxAusbildung} from '@dv/shared/backend/model/jax-ausbildung';
import type {IPersistable, IRestModel} from '@dv/shared/code';
import {checkPresent, DvbUtil, isPresent} from '@dv/shared/code';

export class Ausbildung implements IPersistable, IRestModel<JaxAusbildung> {

    public constructor(
        public id: string | null = null,
        public name: string | null = null,
        public parentId: string | null = null,
        public children: Ausbildung[] = [],
    ) {
    }

    public static apiResponseTransformer(data: any): Ausbildung {
        const ausbildung = new Ausbildung(data.id, data.name, data.parentId);

        if (Array.isArray(data.children)) {
            ausbildung.children = data.children.map((child: any) => Ausbildung.apiResponseTransformer(child));
        }

        return ausbildung;
    }

    public toRestObject(): JaxAusbildung {
        return {
            id: this.id ?? undefined,
            name: checkPresent(this.name),
            parentId: this.parentId ?? undefined,
            children: this.children.map(child => child.toRestObject()),
        };
    }

    public flatten(): Ausbildung[] {
        return [this, ...this.children.flatMap(ausbildung => ausbildung.flatten())];
    }

    public removeChild(child: Ausbildung): void {
        DvbUtil.removeFromArray(child, this.children);
    }

    public findChildIds(): string[] {
        return this.flatten().filter(ausbildung => isPresent(ausbildung.id))
            .map(DvbUtil.mapToIdChecked);
    }
}
