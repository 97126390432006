/*
 * Copyright © 2024 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {KinderOrt, KinderOrtFraktion} from '@dv/kitadmin/models';
import {KinderOrtFraktionTransformer, KinderOrtTransformer} from '@dv/kitadmin/models';
import type {EntityId} from '@dv/shared/backend/model/entity-id';
import type {JaxAnstellungStandort} from '@dv/shared/backend/model/jax-anstellung-standort';
import type {IPersistable, IRestModel, Persisted} from '@dv/shared/code';
import {DvbRestUtil} from '@dv/shared/code';
import {DailyAnstellung} from './DailyAnstellung';

export class AnstellungStandort implements IPersistable, IRestModel<JaxAnstellungStandort> {
    public constructor(
        public id: string | null = null,
        public workTimeModelId: EntityId | undefined = undefined,
        public arbeitspensumProzent: number | null = null,
        public kinderOrtIds: string[] = [],
        public kinderOrte: Persisted<KinderOrt>[] = [],
        public fraktionIds: string[] = [],
        public fraktionen: Persisted<KinderOrtFraktion>[] = [],
        public dailyAnstellungen: DailyAnstellung[] = [],
        public vertretungsperson: boolean = false,
        public alleKinderOrte: boolean = false,
        public alleFraktionen: boolean = false,
    ) {
    }

    public static apiResponseTransformer(data: any): AnstellungStandort {
        const result = new AnstellungStandort(
            data.id,
            data.weeklyHours,
            data.arbeitspensumProzent,
        );

        result.kinderOrte = DvbRestUtil.transformArray(data.kinderOrte, KinderOrtTransformer.create());
        result.kinderOrtIds = data.kinderOrtIds;

        result.fraktionen = DvbRestUtil.transformArray(data.fraktionen, KinderOrtFraktionTransformer.create());
        result.fraktionIds = data.fraktionIds;

        result.dailyAnstellungen = DvbRestUtil.transformArray(data.dailyAnstellungen, DailyAnstellung);
        result.workTimeModelId = data.workTimeModelId;

        result.vertretungsperson = data.vertretungsPerson;
        result.alleKinderOrte = data.alleKinderOrte;
        result.alleFraktionen = data.alleFraktionen;

        return result;
    }

    public toRestObject(): JaxAnstellungStandort {
        return {
            id: this.id ?? undefined,
            workTimeModelId: this.workTimeModelId,
            arbeitspensumProzent: this.arbeitspensumProzent ?? undefined,
            dailyAnstellungen: this.dailyAnstellungen.map(da => da.toRestObject()),
            kinderOrtIds: this.kinderOrtIds,
            fraktionIds: this.fraktionIds,
            vertretungsPerson: this.vertretungsperson,
            alleKinderOrte: this.alleKinderOrte,
            alleFraktionen: this.alleFraktionen,
        };
    }

    public isValid(): unknown {
        return this.alleKinderOrte
            || this.alleFraktionen
            || this.kinderOrtIds.length > 0
            || this.fraktionIds.length > 0;
    }
}
