/*
 * Copyright © 2022 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import {isPresent} from '@dv/shared/code';
import {AnwesenheitsSoll} from './AnwesenheitsSoll';

export class AnwesenheitsSollVerbrauch {

    public hasSoll: boolean = false;

    public constructor(
        public soll: AnwesenheitsSoll | null = null,
        public verbrauch: AnwesenheitsSoll | null = null,
    ) {
        this.hasSoll = isPresent(this.soll?.tage)
        || isPresent(this.soll?.halbtage)
        || isPresent(this.soll?.fruehbetreuungen)
        || isPresent(this.soll?.mittage)
        || isPresent(this.soll?.halbeNachmittage);
    }

    public static apiResponseTransformer(data: any): AnwesenheitsSollVerbrauch {
        return new AnwesenheitsSollVerbrauch(
            AnwesenheitsSoll.apiResponseTransformer(data.soll),
            AnwesenheitsSoll.apiResponseTransformer(data.verbrauch));
    }
}
