/*
 * Copyright © 2021 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {Memoizer} from '@dv/shared/code';
import type {UserSettingsMemoizedKey, UserSettingsMemoizedTypeMap} from './userSettingsStore';

export class UserSettingsMemoizer<T extends UserSettingsMemoizedKey> {

    public constructor(private memoizerAccessor: () => Memoizer<UserSettingsMemoizedTypeMap[T]>) {
    }

    public reset(): void {
        this.memoizerAccessor().reset();
    }

    public clear(p: string): void {
        this.memoizerAccessor().clear(p);
    }

    public get(p: string): UserSettingsMemoizedTypeMap[T] {
        return this.memoizerAccessor().get(p);
    }

    public set(p: string, value: UserSettingsMemoizedTypeMap[T]): void {
        this.memoizerAccessor().set(p, value);
    }
}
