/*
 * Copyright © 2018 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import type {MatrixParams, SearchResultType} from '@dv/shared/code';
import {DvbRestUtil, DvbUtil, ENTITY_SEARCH_TYPE, SearchResultEntry, TypeUtil} from '@dv/shared/code';
import {StateService} from '@uirouter/core';
import type {Observable} from 'rxjs';
import {map} from 'rxjs';
import {CONFIG} from '../../../config';
import {ENTITY_TRANSFORMER} from '../model/EntitySearchType';
import type {MandantSearchFilter} from '../model/MandantSearchFilter';
import type {ArchiveSearchMode} from '../model/SearchArchivalProperty';
import {SEARCH_RESULT_ROUTES} from '../model/SearchResultRoutes';

export const SEARCH_IN_ALL_MANDANTEN = 'searchInAllMandanten';
export const SEARCH_ENTITIES = 'searchEntities';

@Injectable({
    providedIn: 'root',
})
export class SearchService {

    private static baseUrl: string = `${CONFIG.restBackend}/api/v1/search`;

    public constructor(
        private readonly http: HttpClient,
        private readonly $state: StateService,
    ) {
    }

    public static getEntityHrefArgs(
        entityName: SearchResultType,
        id: string,
        subRoute?: string,
    ): { to: string; params: { [index: string]: string } } {
        let to = SEARCH_RESULT_ROUTES[entityName].to;

        if (TypeUtil.isFunction(to)) {
            to = to(subRoute);
        }

        const idParam = SEARCH_RESULT_ROUTES[entityName].idParam;
        const params: { [index: string]: string } = {};
        params[idParam] = id;

        return {to, params};
    }

    private static transformSource(entry: SearchResultEntry, source: unknown): unknown {
        if (!source) {
            return;
        }

        if (ENTITY_SEARCH_TYPE.guard(entry.entity)) {
            return ENTITY_TRANSFORMER[entry.entity]?.apiResponseTransformer(source);
        }

        throw new Error(`No source transformer implementation for ${JSON.stringify(entry.entity)}`);
    }

    public setSearchEntities(entities: string): void {
        localStorage.setItem(SEARCH_ENTITIES, entities);
    }

    public getSearchEntities(): string | null {
        return localStorage.getItem(SEARCH_ENTITIES);
    }

    public setSearchInAllMandanten(value: boolean): void {
        localStorage.setItem(SEARCH_IN_ALL_MANDANTEN, String(value));
    }

    public getSearchInAllMandanten(): boolean {
        return localStorage.getItem(SEARCH_IN_ALL_MANDANTEN) === 'true';
    }

    public searchGlobal$(
        searchText: string,
        findAll?: boolean,
        archiveMode?: ArchiveSearchMode,
    ): Observable<SearchResultEntry[]> {
        const url = `${SearchService.baseUrl}/global/${encodeURIComponent(searchText)}`;

        const entities = this.getSearchEntities();
        const anyMandant = this.getSearchInAllMandanten();
        const params: MatrixParams = {
            findAll,
            entities: DvbUtil.isNotEmptyString(entities) ? `(${entities})` : undefined,
            anyMandant: anyMandant || undefined,
            archiveMode,
        };

        return this.http.get<SearchResultEntry[]>(url + DvbRestUtil.encodeMatrixParams(params)).pipe(
            map((result: any) => result.entries.map(SearchResultEntry.apiResponseTransformer)),
        );
    }

    public redirectToEntity(
        entityName: SearchResultType,
        id: string,
        openInNewWindow: boolean = false,
        subRoute?: string,
    ): void {

        if (openInNewWindow) {
            window.open(this.getEntityHref(entityName, id, subRoute));

            return;
        }

        const {to, params} = SearchService.getEntityHrefArgs(entityName, id, subRoute);
        this.$state.go(to, params);
    }

    public getEntityHref(
        entityName: SearchResultType,
        id: string,
        subRoute?: string,
    ): string {

        const {to, params} = SearchService.getEntityHrefArgs(entityName, id, subRoute);

        return this.$state.href(to, params, {absolute: true});
    }

    /**
     * Execute search in the given entity.
     */
    public searchEntity$(
        searchText: string,
        params: {
            entities: string;
            expandEntity: boolean;
        },
        mandantFilter?: MandantSearchFilter,
    ): Observable<SearchResultEntry[]> {

        const mandantPath = mandantFilter?.path ?? '';
        const url = `${SearchService.baseUrl}${mandantPath}/entity/${encodeURIComponent(searchText)}`;

        return this.http.get(url + DvbRestUtil.encodeMatrixParams(params)).pipe(map((result: any) => {
            const entries = result.entries;
            if (!params.expandEntity) {
                return result.entries;
            }

            // Hydrate `entry.source`
            entries.forEach((entry: any) => {
                entry.source = SearchService.transformSource(entry, entry.source);
            });

            return entries;
        }));
    }
}
