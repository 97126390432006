/*
 * Copyright © 2018 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {
    Belegung,
    Bewerbung,
    CustomFieldValue,
    ExtraPlatz,
    GruppenBelegung,
    Kind,
    Kontingente,
} from '@dv/kitadmin/models';
import {ServiceContainer} from '@dv/kitadmin/models';
import type {DayOfWeek, KindergartenBelegung} from '@dv/shared/code';
import {BelegungsZustand, checkPresent, DvbDateUtil, DvbUtil, isPresent} from '@dv/shared/code';
import type angular from 'angular';
import type moment from 'moment';
import type {ZeitraumIdsByFraktion} from '../../../filter/zeitraum/KinderOrtZeitraumFilterModel';
import {MinMaxFilterProperty} from './MinMaxFilterProperty';

export class KindFilterModel {
    public static $inject: readonly string[] = [];

    public constructor(
        public privat: boolean = false,
        public subventioniert: boolean = false,
        public firmenIds: string[] = [],
        public firmenKontingentIds: string[] = [],
        public gruppenIds: string[] = [],
        public betreuungsZeitraeumeIds: ZeitraumIdsByFraktion = {},
        public gewuenschteBetreuungAb: moment.Moment | null = null,
        public geburtsTagOrTermin: moment.Moment | null = null,
        public angebot: boolean = false,
        public provisorisch: boolean = false,
        public belegt: boolean = false,
        public keineZuweisung: boolean = false,
        public belegungen: Belegung[] = [],
        public kindergartenBelegung: KindergartenBelegung | null = null,
        public belegungCustomFieldIds: string[] = [],
        public geschwister: boolean = false,
        public pensum: MinMaxFilterProperty = new MinMaxFilterProperty(),
        public anmeldeDatum: moment.Moment | null = null,
        public prioritaet: number | null = null,
    ) {
    }

    public static createFromBelegung(
        belegung: Belegung,
        absences: ExtraPlatz[],
        kontingente: Kontingente[],
        gruppenIds: string[],
        verfuegbarAb: moment.Moment | null,
        kind: Kind,
    ): angular.IPromise<KindFilterModel> {
        const model = new KindFilterModel();

        const platzTypen = belegung.platzTypen(kontingente, gruppenIds);
        model.privat = platzTypen.privat;
        model.subventioniert = platzTypen.subventioniert;
        model.firmenIds = platzTypen.firmen.filter(f => !!f.id).map(f => checkPresent(f.id));
        model.firmenKontingentIds = platzTypen.firmenKontingentIds;
        if (DvbDateUtil.isValidMoment(verfuegbarAb)) {
            model.gewuenschteBetreuungAb = verfuegbarAb;
        }
        model.geburtsTagOrTermin = checkPresent(kind.getGeburtsTagOrTermin());
        model.gruppenIds = belegung.gruppenBelegungen.filter(g => !!g.id).map(gb => checkPresent(gb.gruppeId));
        model.angebot = belegung.belegungsZustand === BelegungsZustand.ANGEBOT_ERSTELLT;
        model.provisorisch = belegung.belegungsZustand === BelegungsZustand.PROVISORISCH;
        model.belegt = belegung.belegungsZustand === BelegungsZustand.BELEGT;
        model.belegungCustomFieldIds = KindFilterModel.getBelegungCustomFieldIds(belegung);
        model.kindergartenBelegung = belegung.kindergartenBelegung;
        model.geschwister = kind.hasGeschwister();
        model.anmeldeDatum = kind.anmeldeDatum;
        model.pensum = new MinMaxFilterProperty(belegung.pensum, belegung.pensum);

        return ServiceContainer.$q.all(belegung.gruppenBelegungen.map(
            gb => this.setBetreuungsZeitraeumeIdsFromGruppenBelegung(model, gb))).then(() => {
            this.removeAbsenceZeitraeumeIds(model, absences);

            return model;
        });
    }

    public static createFromBewerbung(
        bewerbung: Bewerbung,
        kitaKontingente: Kontingente[],
        kitaGruppenIds: string[],
        firstOfWeek: moment.Moment,
        kind: Kind,
        belegungen: Belegung[],
    ): angular.IPromise<KindFilterModel> {
        const model = new KindFilterModel();

        const platzTypen = bewerbung.platzTypen(kitaKontingente);
        model.belegungen = belegungen;
        model.privat = platzTypen.privat;
        model.subventioniert = platzTypen.subventioniert;
        model.firmenIds = platzTypen.firmen.filter(g => !!g.id).map(f => checkPresent(f.id));
        model.firmenKontingentIds = platzTypen.firmenKontingentIds;
        model.geburtsTagOrTermin = checkPresent(kind.getGeburtsTagOrTermin());
        model.gruppenIds = kitaGruppenIds;
        model.gewuenschteBetreuungAb = bewerbung.gewuenschteBetreuungAb;
        model.belegungCustomFieldIds =
            DvbUtil.uniqueArray(belegungen.flatMap(belegung => KindFilterModel.getBelegungCustomFieldIds(belegung)));
        model.geschwister = kind.hasGeschwister();
        model.anmeldeDatum = kind.anmeldeDatum;
        model.pensum = new MinMaxFilterProperty(bewerbung.pensum.von, bewerbung.pensum.bis);
        model.prioritaet = bewerbung.prioritaet;

        model.updateStatus(firstOfWeek);

        return KindFilterModel.getBelegungsEinheitenByWochentagFromBewerbung(bewerbung).then(
            betreuungsZeitraeumeIds => {
                kitaGruppenIds.forEach(id => {
                    model.betreuungsZeitraeumeIds[id] = betreuungsZeitraeumeIds;
                });

                return model;
            });
    }

    private static setBetreuungsZeitraeumeIdsFromGruppenBelegung(
        model: KindFilterModel,
        gruppenBelegung: GruppenBelegung,
    ): angular.IPromise<any> {

        const promises: angular.IPromise<any>[] = [];
        gruppenBelegung.plaetze.forEach(p => {
            const params = {cache: true};
            const promise = ServiceContainer.wochenplanService
                .getBetreuungsZeitraeumeByBelegungsEinheitId(checkPresent(p.belegungsEinheitId), params);

            promises.push(promise);

            promise.then(z => {
                const gruppeId = checkPresent(gruppenBelegung.gruppeId);
                if (!(gruppeId in model.betreuungsZeitraeumeIds)) {
                    model.betreuungsZeitraeumeIds[gruppeId] = {
                        MONDAY: [],
                        TUESDAY: [],
                        WEDNESDAY: [],
                        THURSDAY: [],
                        FRIDAY: [],
                        SATURDAY: [],
                        SUNDAY: [],
                    };
                }

                const wochentag = checkPresent(p.wochentag);
                if (!(wochentag in model.betreuungsZeitraeumeIds[gruppeId])) {
                    model.betreuungsZeitraeumeIds[gruppeId][wochentag] = [];
                }

                z.forEach(zeitraum => {
                    const gruppeIdNonNull = checkPresent(gruppenBelegung.gruppeId);
                    const wochentagNonNull = checkPresent(p.wochentag);
                    const zeitraumId = checkPresent(zeitraum.id);

                    const zeitraumIds =
                        checkPresent(model.betreuungsZeitraeumeIds[gruppeIdNonNull][wochentagNonNull]);
                    if (!zeitraumIds.includes(zeitraumId)) {
                        zeitraumIds.push(zeitraumId);
                    }
                });
            });
        });

        return ServiceContainer.$q.all(promises);
    }

    private static removeAbsenceZeitraeumeIds(model: KindFilterModel, absences: ExtraPlatz[]): void {
        absences.forEach(absence => {
            const absenceZeitraumIds = absence.belegungsEinheit!.zeitraumIds;

            const betreuungsZeitraeumeId = model.betreuungsZeitraeumeIds[absence.kinderOrtFraktionId!];
            if (!betreuungsZeitraeumeId?.[absence.wochentag!]) {
                // Happens when absences cover the entire belegung --> nop
                return;
            }

            const current = betreuungsZeitraeumeId[absence.wochentag!] ?? [];
            betreuungsZeitraeumeId[absence.wochentag!] = current.filter(id => !absenceZeitraumIds.includes(id));
        });
    }

    private static getBelegungsEinheitenByWochentagFromBewerbung(bewerbung: Bewerbung): angular.IPromise<any> {
        const promises: angular.IPromise<any>[] = [];
        const idsByWochentag: { [key in DayOfWeek]: string[] } = {
            MONDAY: [],
            TUESDAY: [],
            WEDNESDAY: [],
            THURSDAY: [],
            FRIDAY: [],
            SATURDAY: [],
            SUNDAY: [],
        };

        bewerbung.belegteEinheiten.forEach(belegteEinheit => {
            belegteEinheit.belegungsEinheitenIds.forEach(belegungsEinheitId => {
                const params = {cache: true};
                const promise = ServiceContainer.wochenplanService
                    .getBetreuungsZeitraeumeByBelegungsEinheitId(belegungsEinheitId, params);

                promises.push(promise);

                const wochentag = checkPresent(belegteEinheit.wochentag);
                promise.then(z => {
                    if (!(wochentag in idsByWochentag)) {
                        idsByWochentag[wochentag] = [];
                    }

                    z.forEach(zeitraum => {
                        if (!idsByWochentag[wochentag].includes(zeitraum.id)) {
                            idsByWochentag[wochentag].push(zeitraum.id);
                        }
                    });
                });
            });
        });

        return ServiceContainer.$q.all(promises).then(() => idsByWochentag);
    }

    private static getBelegungCustomFieldIds(belegung: Belegung): string[] {
        return belegung.customFieldValues
            .filter(customValue => KindFilterModel.includeCustomFieldValue(customValue))
            .map(cfv => checkPresent(cfv.customField.id));
    }

    private static includeCustomFieldValue(customValue: CustomFieldValue): boolean {
        switch (customValue.customField.fieldType) {
            case 'STRING':
                return isPresent(customValue.value.value);
            case 'BOOLEAN':
                // Return boolean fields only when they are set (true)
                return customValue.value.value === true;
            default:
                throw new Error(`CustomField for ParamValueDType ${customValue.customField.fieldType} not implemented`);
        }
    }

    public updateStatus(firstOfWeek: moment.Moment): void {

        const futureBelegungen = this.belegungen
            .filter(belegung => checkPresent(belegung.gueltigBis).isSameOrAfter(firstOfWeek));

        this.angebot = futureBelegungen
            .reduce<boolean>((
                hasAngebot,
                belegung,
            ) => hasAngebot || belegung.belegungsZustand === BelegungsZustand.ANGEBOT_ERSTELLT, false);
        this.provisorisch = futureBelegungen
            .reduce<boolean>((
                hasProvisorisch,
                belegung,
            ) => hasProvisorisch || belegung.belegungsZustand === BelegungsZustand.PROVISORISCH, false);
        this.belegt = futureBelegungen
            .reduce<boolean>((
                isBelegt,
                belegung,
            ) => isBelegt || belegung.belegungsZustand === BelegungsZustand.BELEGT, false);

        this.keineZuweisung = !this.angebot && !this.provisorisch && !this.belegt;
    }
}
