/*
 * Copyright © 2018 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {
    AnwesenheitCustomField,
    Belegung,
    Firma,
    GruppenWochenBelegung,
    KinderOrtFraktion,
    KinderOrtId,
    PlatzTypen,
} from '@dv/kitadmin/models';
import {AnwesenheitsZeit, CustomFieldValueNotNamed, ZeitraumUtil} from '@dv/kitadmin/models';
import type {DayOfWeek} from '@dv/shared/code';
import {checkPresent, DvbDateUtil, DvbUtil, isNullish} from '@dv/shared/code';
import type moment from 'moment';
import type {FraktionService} from '../../../common/service/rest/kinderort/fraktionService';
import type {BewerbungStrategy} from '../../../kinderort/component/dvb-kita-kinder/BewerbungStrategy';
import type {SchliesstagDateRange} from '../../../schliesstage/models/SchliesstagDateRange';
import {MonatsBelegungZeitInput} from './MonatsBelegungZeitInput';

export class MonatsBelegungInputRow {

    public betreuungsZeitraeumeIds: string[] = [];
    public dayOfWeek: DayOfWeek;
    public isOverMaxDailyHours: boolean = false;
    public allEmpty: boolean = true;
    public areAllSchliesstage: boolean = false;
    public hasCustomFieldValues: boolean = false;

    public constructor(
        private fraktionService: FraktionService,
        private bewerbungStrategy: BewerbungStrategy,
        public maxDailyHours: Map<KinderOrtId, number>,
        public date: moment.Moment,
        public inputsByZeitraumId: Map<string, MonatsBelegungZeitInput> = new Map<string, MonatsBelegungZeitInput>(),
        public customFieldValues: CustomFieldValueNotNamed[] | undefined = [],
    ) {
        this.dayOfWeek = DvbDateUtil.getDayOfWeek(this.date.isoWeekday());
    }

    public initHasCustomFieldValues(): void {
        this.hasCustomFieldValues = this.customFieldValues?.some(element => element.value.value) ?? false;
    }

    public initBetreuungsZeitraeume(
        gruppen: KinderOrtFraktion[],
        defaultGruppe: KinderOrtFraktion,
        customFields: AnwesenheitCustomField[],
        schliesstagDateRangesByKinderOrtId: Map<KinderOrtId, SchliesstagDateRange[]>,
        allowedEditVon: moment.Moment | null = null,
        allowedEditBis: moment.Moment | null = null,
    ): void {

        const availableGruppen = DvbDateUtil.getEntitiesOn(gruppen, this.date);
        const zeitreaumeByDayOfWeek = ZeitraumUtil.mergeTagesplaene(availableGruppen, this.dayOfWeek);
        const betreuungsZeitraeume =
            DvbUtil.uniqueArray(Array.from(zeitreaumeByDayOfWeek.values()).flat(), DvbUtil.mapToId);

        betreuungsZeitraeume.forEach(betreuungsZeitraum => {
            const emptyCustomFieldValues = customFields.map(field => new CustomFieldValueNotNamed(null, field));
            const anwesenheitsZeit = new AnwesenheitsZeit(
                null,
                this.date,
                betreuungsZeitraum.id,
                null,
                null,
                betreuungsZeitraum,
                emptyCustomFieldValues);

            const zeitInput = new MonatsBelegungZeitInput(
                this.bewerbungStrategy,
                anwesenheitsZeit,
                defaultGruppe,
                availableGruppen,
                schliesstagDateRangesByKinderOrtId,
                allowedEditVon,
                allowedEditBis);

            this.inputsByZeitraumId.set(betreuungsZeitraum.id, zeitInput);
        });

        this.betreuungsZeitraeumeIds = Array.from(this.inputsByZeitraumId.keys());
    }

    public initBetreuungsZeitraumBelegungen(gruppenWochenBelegungen: GruppenWochenBelegung[]): void {
        const active = DvbDateUtil.getEntitiesOn(gruppenWochenBelegungen, this.date);
        active.forEach(gruppenWochenBelegung => {
            const gruppeId = gruppenWochenBelegung.gruppeId!;

            gruppenWochenBelegung.betreuungsZeitraumBelegung
                .filter(bzb => bzb.wochentag === this.dayOfWeek)
                .filter(bzb => bzb.zeitraumId && this.inputsByZeitraumId.has(bzb.zeitraumId))
                .forEach(bzb => {
                    const zeitInput = checkPresent(this.inputsByZeitraumId.get(checkPresent(bzb.zeitraumId)));

                    zeitInput.betreuungsZeitraumBelegungByGruppeId.set(gruppeId, bzb);
                });
        });

        this.inputsByZeitraumId.forEach((zeitInput: MonatsBelegungZeitInput) => {
            zeitInput.init();
        });
        this.updateAreAllSchliesstage();
    }

    /**
     * @param belegungen the belegungen to apply
     * @param firmen the available firmen, relevant for the belegungs firmenplaetze
     * @param defaultPlatzTypen the initial PlatzKontingentType
     * @param fraktionen already available fraktionen within which needed fraktionIds can be looked up
     */
    public applyBelegungen(
        belegungen: Belegung[],
        firmen: Firma[],
        defaultPlatzTypen: PlatzTypen,
        fraktionen: KinderOrtFraktion[],
    ): void {
        const belegung = DvbDateUtil.getEntityOn(belegungen, this.date);
        if (!belegung) {
            return;
        }

        Array.from(this.inputsByZeitraumId.values()).forEach(input => {
            input.platzTyp.from(defaultPlatzTypen);
        });

        belegung.gruppenBelegungen.forEach(gruppenBelegung => {
            gruppenBelegung.plaetze
                .filter(platz => platz.wochentag === this.dayOfWeek)
                .forEach(platz => checkPresent(platz.belegungsEinheit).zeitraumIds
                    .forEach(zeitraumId => {
                        if (!this.inputsByZeitraumId.has(zeitraumId)) {
                            throw new Error(`Zeitraum with id ${zeitraumId} not initialized. ${
                                this.dayOfWeek} in fraktion ${gruppenBelegung.gruppe?.getDisplayName()}`);
                        }
                        const zeitInput = this.inputsByZeitraumId.get(zeitraumId)!;

                        if (zeitInput.anwesenheit.von === null && zeitInput.anwesenheit.bis === null) {
                            // we have a planned Belegung, without specified times -> prefill with the Zeitraum data
                            zeitInput.setTimesFromBetreuungsZeitraum();
                        }

                        zeitInput.platzTyp.setTypeByKontingent(platz.kontingent, firmen);
                        zeitInput.belegungsZustand = belegung.belegungsZustand!;

                        this.showOtherGruppe(gruppenBelegung.gruppeId!, fraktionen, zeitInput);
                    }));
        });
    }

    public applyAnwesenheitsZeit(anwesenheitsZeit: AnwesenheitsZeit): void {
        if (!this.inputsByZeitraumId.has(anwesenheitsZeit.betreuungsZeitraumId!)) {
            return;
        }

        const zeitInput = this.inputsByZeitraumId.get(anwesenheitsZeit.betreuungsZeitraumId!)!;
        if (isNullish(zeitInput.allowedEditVon) || isNullish(zeitInput.allowedEditBis)
            || this.date.isBetween(zeitInput.allowedEditVon, zeitInput.allowedEditBis, 'day', '[]')) {

            this.allEmpty = false;
            zeitInput.anwesenheit.von = anwesenheitsZeit.von;
            zeitInput.anwesenheit.bis = anwesenheitsZeit.bis;
            zeitInput.anwesenheit.customFieldValues = anwesenheitsZeit.customFieldValues;
            zeitInput.initHasCustomFieldValues();
        }
    }

    /**
     * Changes the given inputs group.
     */
    public changeGruppe(
        zeitInput: MonatsBelegungZeitInput,
        gruppe: KinderOrtFraktion,
    ): void {
        zeitInput.changeGruppe(gruppe);
        this.updateAnwesenheitsZeitValidation();
        this.updateAreAllSchliesstage();
    }

    /**
     * Checks if the anwesenheitszeit conforms to the respective fraktions constraints.
     * Call this whenever the anwesenheitszeiten or the fraktion changes.
     */
    public updateAnwesenheitsZeitValidation(): void {
        if (this.maxDailyHours.size === 0) {
            return;
        }

        const zeitInputs = Array.from(this.inputsByZeitraumId.values())
            .filter(zeitInput => zeitInput.showInput)
            .filter(zeitInput => zeitInput.anwesenheit.von && zeitInput.anwesenheit.bis);

        const inputsByKinderOrtId = zeitInputs.reduce(
            (entryMap, zi) => {
                const kinderOrtId = checkPresent(zi.selectedGruppe.kinderOrtId);

                return entryMap.set(kinderOrtId, [...entryMap.get(kinderOrtId) ?? [], zi]);
            },
            new Map<KinderOrtId, MonatsBelegungZeitInput[]>(),
        );

        const isValid = Array.from(this.maxDailyHours)
            .every(([kinderOrtId, maxDailyHours]) => {
                const hoursAtKinderOrt = this.calcHoursAtKinderOrt(inputsByKinderOrtId.get(kinderOrtId));

                return hoursAtKinderOrt <= maxDailyHours;
            });

        this.isOverMaxDailyHours = !isValid;
    }

    private updateAreAllSchliesstage(): void {
        let areAllSchliesstage = true;

        this.inputsByZeitraumId.forEach((zeitInput: MonatsBelegungZeitInput) => {
            if (!zeitInput.isSchliesstag) {
                areAllSchliesstage = false;
            }
        });

        this.areAllSchliesstage = areAllSchliesstage;
    }

    private calcHoursAtKinderOrt(kinderOrtInputs?: MonatsBelegungZeitInput[]): number {
        if (!kinderOrtInputs) {
            // no defined time
            return 0;
        }

        return kinderOrtInputs
            .map(zeitInput => zeitInput.anwesenheit)
            .map(anwesenheit => Math.abs(checkPresent(anwesenheit.von).diff(anwesenheit.bis, 'hours', true)))
            .reduce((prev, current) => prev + current, 0);
    }

    private showOtherGruppe(
        gruppeId: string,
        fraktionen: KinderOrtFraktion[],
        zeitInput: MonatsBelegungZeitInput,
    ): void {
        this.fraktionService.fetchForMonatsBelegungInputRow(this.date, gruppeId, fraktionen).then(gruppe => {
            if (!gruppe) {
                return;
            }

            const entityOn = DvbDateUtil.getEntityOn<GruppenWochenBelegung>(gruppe.gruppenWochenBelegungen, this.date);

            if (!entityOn) {
                throw new Error(`GruppenWochenBelegung not found for ${gruppeId} at ${this.date.format('l')}`);
            }

            entityOn.betreuungsZeitraumBelegung
                .filter(bzb => bzb.wochentag === this.dayOfWeek)
                .filter(bzb => bzb.zeitraumId === zeitInput.anwesenheit.betreuungsZeitraumId)
                .forEach(bzb => {
                    zeitInput.betreuungsZeitraumBelegungByGruppeId.set(gruppe.id!, bzb);
                });

            zeitInput.changeGruppe(gruppe);

            if (!zeitInput.availableGruppen.some(g => g.id === gruppe.id)) {
                zeitInput.availableGruppen.push(gruppe);
            }
        });
    }
}
