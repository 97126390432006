/*
 * Copyright © 2020 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {ILimited, IPersistable} from '@dv/shared/code';
import {DvbRestUtil} from '@dv/shared/code';
import type moment from 'moment';

export class SchliesstagDateRange implements IPersistable, ILimited {

    public constructor(
        public id: string | null = null,
        public gueltigAb: moment.Moment | null = null,
        public gueltigBis: moment.Moment | null = null,
    ) {
    }

    public static apiResponseTransformer(data: any): SchliesstagDateRange {
        return new SchliesstagDateRange(
            data.id,
            DvbRestUtil.localDateToMoment(data.gueltigAb),
            DvbRestUtil.localDateToMoment(data.gueltigBis),
        );
    }

    public toRestObject(): Record<string, unknown> {
        return {
            id: this.id,
            gueltigAb: DvbRestUtil.momentToLocalDate(this.gueltigAb),
            gueltigBis: DvbRestUtil.momentToLocalDate(this.gueltigBis),
        };
    }
}
