/*
 * Copyright © 2019 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {Ng1StateDeclaration} from '@uirouter/angularjs';
import type angular from 'angular';
import type {Benutzer} from '../benutzer/model/Benutzer';
import {trans} from '../common/i18n/trans';
import type {BenutzerService} from '../common/service/rest/benutzer/benutzerService';

export const DASHBOARD_BASE_STATE = {
    name: 'base.dashboard',
    abstract: true,
    resolve: {
        $title: [
            '$translate', ($translate: angular.translate.ITranslateService): angular.IPromise<string> =>
                trans($translate, 'DASHBOARD.TITLE'),
        ],
    },
} satisfies Ng1StateDeclaration;

export const DASHBOARD_STATE = {
    name: 'base.dashboard.default',
    url: '/dashboard',
    resolve: {
        benutzer: [
            'benutzerService',
            (
                benutzerService: BenutzerService,
            ): angular.IPromise<Benutzer> => benutzerService.getCurrent(),
        ],
    },
    views: {
        '': {
            template: '<dvb-dashboard benutzer="$resolve.benutzer"></dvb-dashboard>',
        },
        'pageHeader@^.^': {
            template: '<dvb-dashboard-greeting benutzer="$resolve.benutzer"></dvb-dashboard-greeting>',
        },
    },
} satisfies Ng1StateDeclaration;

export const EMPTY_DASHBOARD_STATE = {
    name: 'base.dashboard.empty',
    url: '/dashboard-leer',
    resolve: {
        benutzer: [
            'benutzerService',
            (
                benutzerService: BenutzerService,
            ): angular.IPromise<Benutzer> => benutzerService.getCurrent({includes: '(loginTokens.fields(kind))'}),
        ],
    },
    views: {
        '': {
            template: '<dvb-dashboard-empty benutzer="$resolve.benutzer"></dvb-dashboard-empty>',
        },
        'pageHeader@^.^': {
            template: '<dvb-dashboard-greeting benutzer="$resolve.benutzer"></dvb-dashboard-greeting>',
        },
    },
    data: {
        permission: [],
    },
} satisfies Ng1StateDeclaration;
