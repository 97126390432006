/*
 * Copyright © 2021 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {BetreuungsPerson} from '@dv/kitadmin/models';
import {Kind, KinderOrtFraktionTransformer, Termin} from '@dv/kitadmin/models';
import type {BackendLocalDate} from '@dv/shared/backend/model/backend-local-date';
import type {IDisplayable, IEntityList, IPersistable, IRestModel, ISearchable, Persisted} from '@dv/shared/code';
import {Adresse, checkPresent, DvbRestUtil, SearchResultEntry} from '@dv/shared/code';
import type moment from 'moment/moment';
import {AngestellteZuweisungZeit} from '../../model/AngestellteZuweisungZeit';
import {Anstellung} from './Anstellung';

export class Angestellte implements IPersistable, IRestModel, IEntityList, IDisplayable, ISearchable {

    public constructor(
        public id: string | null = null,
        public vorName: string | null = null,
        public familienName: string | null = null,
        public adresse: Adresse | null = null,
        public telefon: string | null = null,
        public email: string | null = null,
        public geburtsTag: moment.Moment | null = null,
        public benutzerId: string | null = null,
        public anstellungen: Anstellung[] = [],
        public betreuungsPersonen: Persisted<BetreuungsPerson>[] = [],
        public zuweisungZeiten: { [date: BackendLocalDate]: AngestellteZuweisungZeit[] } = {},
        public pauseZeiten: { [date: BackendLocalDate]: AngestellteZuweisungZeit[] } = {},
        public termine: Termin[] = [],
        public bezugsKinder: Kind[] = [],
        public springer: boolean | null = null,
    ) {
    }

    public static apiResponseTransformer(data: any): Angestellte {
        const result = new Angestellte(data.id);
        result.vorName = data.vorName;
        result.familienName = data.familienName;
        result.telefon = data.telefon;
        result.email = data.email;
        result.geburtsTag = DvbRestUtil.localDateToMoment(data.geburtsTag);
        result.benutzerId = data.benutzerId;
        result.springer = data.springer;

        if (data.adresse) {
            result.adresse = Adresse.apiResponseTransformer(data.adresse);
        }

        const zuweisungZeiten: any = {};
        Object.entries(data.zuweisungZeiten ?? []).forEach(([date, timeRanges]) => {
            zuweisungZeiten[date] = DvbRestUtil.transformArray(timeRanges, AngestellteZuweisungZeit);
        });

        const pauseZeiten: any = {};
        Object.entries(data.pauseZeiten ?? []).forEach(([date, timeRanges]) => {
            pauseZeiten[date] = DvbRestUtil.transformArray(timeRanges, AngestellteZuweisungZeit);
        });

        result.anstellungen = DvbRestUtil.transformArray(data.anstellungen, Anstellung);
        result.zuweisungZeiten = zuweisungZeiten;
        result.pauseZeiten = pauseZeiten;
        result.termine = DvbRestUtil.transformArray(data.termine, Termin);
        result.bezugsKinder = DvbRestUtil.transformArray(data.bezugsKinder, Kind);

        if (Array.isArray(data.betreuungsPersonen)) {
            const transformer = KinderOrtFraktionTransformer.create();
            result.betreuungsPersonen =
                data.betreuungsPersonen.map((fraktion: any) => transformer.apiResponseTransformer(fraktion));
        }

        return result;
    }

    public entityId(): string {
        return checkPresent(this.id);
    }

    public badgeText(): string | undefined {
        return undefined;
    }

    public tooltipText(): string | undefined {
        return undefined;
    }

    public getDisplayName(): string {
        return `${this.familienName} ${this.vorName}`;
    }

    public toRestObject(): Record<string, unknown> {
        return {
            id: this.id,
            vorName: this.vorName,
            familienName: this.familienName,
            adresse: this.adresse ? this.adresse.toRestObject() : null,
            telefon: this.telefon,
            email: this.email,
            geburtsTag: DvbRestUtil.momentToLocalDate(this.geburtsTag),
            benutzerId: this.benutzerId,
        };
    }

    public toSearchResultEntry(): SearchResultEntry {
        return new SearchResultEntry('ANGESTELLTE', this.id!, this.getDisplayName());
    }
}
