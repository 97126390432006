/*
 * Copyright © 2018 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import {REST_BACKEND} from '@dv/shared/backend/api';
import type {SupportedLocale} from '@dv/shared/code';
import {DEFAULT_LOCALE} from '@dv/shared/code';

interface IConfig {

    /** Default locale to be used when no other locale the user wants is supported. */
    defaultLocale: SupportedLocale;

    /** URL to the REST API */
    restBackend: string;
}

// if migrating services/components/... to dvlib: migrate your stuff to @app/dvlib/ConfigService
export const CONFIG: Readonly<IConfig> = {
    defaultLocale: DEFAULT_LOCALE,
    restBackend: REST_BACKEND,
};
