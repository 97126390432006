/*
 * Copyright © 2018 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {
    AnwesenheitCustomField,
    AnwesenheitsZeit,
    BelegungsEinheit,
    Firma,
    KinderOrt,
    KinderOrtFraktion,
    KinderOrtId,
    Wochenplan,
} from '@dv/kitadmin/models';
import {
    Belegung,
    CustomFieldValueNotNamed,
    GruppenBelegung,
    MonatsBelegung,
    Platz,
    PlatzTypen,
    VertraglichesPensum,
    ZeitraumUtil,
} from '@dv/kitadmin/models';
import type {BackendLocalDate} from '@dv/shared/backend/model/backend-local-date';
import type {DayOfWeek, Persisted} from '@dv/shared/code';
import {BelegungsZustand, checkPresent, DvbDateUtil, DvbError, DvbRestUtil, DvbUtil} from '@dv/shared/code';
import moment from 'moment';
import type {FraktionService} from '../../common/service/rest/kinderort/fraktionService';
import {BewerbungStrategy} from '../../kinderort/component/dvb-kita-kinder/BewerbungStrategy';
import type {SchliesstagDateRange} from '../../schliesstage/models/SchliesstagDateRange';
import {MonatsBelegungInputRow} from '../zuweisung/dvb-monats-belegung/MonatsBelegungInputRow';
import type {MonatsBelegungZeitInput} from '../zuweisung/dvb-monats-belegung/MonatsBelegungZeitInput';
import type {ZuweisenFormModel} from '../zuweisung/dvb-zuweisen-form/ZuweisenFormModel';

export class MonatsBelegungService {
    public static $inject: readonly string[] = ['fraktionService'];

    public constructor(
        private fraktionService: FraktionService,
    ) {
    }

    private static createPlatz(dayOfWeek: DayOfWeek, belegungsEinheitId: string, kontingentId: string | null): Platz {
        return new Platz(
            null,
            dayOfWeek,
            null,
            belegungsEinheitId,
            null,
            kontingentId,
        );
    }

    private static createBelegung(
        gueltigAb: moment.Moment,
        gueltigBis: moment.Moment,
        zuweisenFormModel: ZuweisenFormModel,
        gruppenBelegungen: GruppenBelegung[] = [],
    ): Belegung {

        const belegung = new Belegung();
        belegung.gueltigAb = gueltigAb;
        belegung.gueltigBis = gueltigBis;
        zuweisenFormModel.applyToBelegung(belegung);
        belegung.gruppenBelegungen = gruppenBelegungen;

        return belegung;
    }

    public doEmptyAnwesenheitsZeiten(inputRows: MonatsBelegungInputRow[]): void {
        inputRows.forEach(row => {
            row.inputsByZeitraumId.forEach(zeitInput => {
                zeitInput.anwesenheit.von = null;
                zeitInput.anwesenheit.bis = null;
            });
        });
    }

    public getDefaultPlatzTypen(belegungen: Belegung[], firmen: Firma[]): PlatzTypen {
        // use any belegung that has plaetze
        const gbWithPlaetze = belegungen.filter(b => b.gruppenBelegungen.length > 0
            && b.gruppenBelegungen.some(gb => gb.plaetze.length > 0))
            .map(b => b.gruppenBelegungen.find(gb => gb.plaetze.length > 0))[0];

        if (!gbWithPlaetze) {
            return PlatzTypen.createPrivat();
        }

        return PlatzTypen.fromKontingent(gbWithPlaetze.plaetze[0].kontingent, firmen);
    }

    public initInputRowsForGruppen(
        gruppen: KinderOrtFraktion[],
        dayOfMonth: moment.Moment,
        customFields: AnwesenheitCustomField[],
        maxDailyHours: Map<KinderOrtId, number>,
        schliesstagDateRangesByKinderOrtId: Map<KinderOrtId, SchliesstagDateRange[]>,
        allowedEditVon: moment.Moment | null = null,
        allowedEditBis: moment.Moment | null = null,
    ): MonatsBelegungInputRow[] {

        const startOfMonth = DvbDateUtil.startOfMonth(moment(dayOfMonth));
        const daysInMonth = startOfMonth.daysInMonth();
        const defaultGruppe = gruppen[0];

        return this.doInitInputRows(
            daysInMonth,
            startOfMonth,
            defaultGruppe,
            gruppen,
            customFields,
            maxDailyHours,
            schliesstagDateRangesByKinderOrtId,
            allowedEditVon,
            allowedEditBis);
    }

    /**
     * Creates a row for every day of the month with an empty AnwesenheitsZeit for every BetreungsZeitraum.
     */
    public initInputRows(
        kita: Persisted<KinderOrt>,
        defaultGruppe: KinderOrtFraktion,
        gruppen: KinderOrtFraktion[],
        dayOfMonth: moment.Moment,
        customFields: AnwesenheitCustomField[],
        schliesstagDateRangesByKinderOrtId: Map<KinderOrtId, SchliesstagDateRange[]>,
    ): MonatsBelegungInputRow[] {

        const startOfMonth = DvbDateUtil.startOfMonth(moment(dayOfMonth));
        const daysInMonth = startOfMonth.daysInMonth();

        const maxDailyHours = new Map<KinderOrtId, number>();
        if (kita.maxDailyHours) {
            maxDailyHours.set(kita.id, kita.maxDailyHours);
        }

        return this.doInitInputRows(
            daysInMonth,
            startOfMonth,
            defaultGruppe,
            gruppen,
            customFields,
            maxDailyHours,
            schliesstagDateRangesByKinderOrtId);
    }

    public setAnwesenheitsZeiten(
        anwesenheitsZeiten: AnwesenheitsZeit[],
        inputRows: MonatsBelegungInputRow[],
    ): void {

        inputRows.forEach(inputRow => {
            const zeiten = anwesenheitsZeiten.filter(anwesenheitsZeit =>
                inputRow.date.isSame(checkPresent(anwesenheitsZeit.datum), 'day'));
            zeiten.forEach(anwesenheitsZeit => inputRow.applyAnwesenheitsZeit(anwesenheitsZeit));

            if (zeiten.length > 0) {
                inputRow.updateAnwesenheitsZeitValidation();
            }
        });
    }

    public setCustomFieldValues(
        customFieldValuesPerDate: Map<BackendLocalDate, CustomFieldValueNotNamed[]>,
        inputRows: MonatsBelegungInputRow[],
    ): void {
        inputRows.forEach(inputRow => {
            const customFieldValues = customFieldValuesPerDate.get(DvbRestUtil.momentToLocalDateChecked(inputRow.date));
            if (customFieldValues) {
                inputRow.customFieldValues = customFieldValues;
            }
            inputRow.initHasCustomFieldValues();
        });
    }

    public shortenBelegungen(belegungen: Belegung[], shortenTo: moment.Moment): Belegung[] {

        const belegungenInRange = belegungen
            .filter(belegung => checkPresent(belegung.gueltigAb).isSameOrBefore(shortenTo));

        // Shorten intersecting belegung by setting gueltigBis and removing plätze that are out of range
        const belegungToShorten = belegungenInRange
            .find(belegung => checkPresent(belegung.gueltigBis).isAfter(shortenTo));

        if (belegungToShorten) {
            this.shortenBelegung(belegungToShorten, shortenTo);
        }

        return belegungenInRange;
    }

    // noinspection JSMethodCanBeStatic
    public hasShortened(originalBelegungen: Belegung[], shortenedBelegungen: Belegung[]): boolean {

        if (originalBelegungen.length === 0) {
            return false;
        }

        const lastBelegung = originalBelegungen[originalBelegungen.length - 1];

        return shortenedBelegungen.length !== originalBelegungen.length ||
            checkPresent(lastBelegung.gueltigBis)
                .isBefore(DvbDateUtil.endOfMonth(moment(checkPresent(lastBelegung.gueltigBis))));
    }

    public createMonatsBelegung(
        inputRows: MonatsBelegungInputRow[],
        startOfMonth: moment.Moment,
        endOfMonth: moment.Moment,
        zuweisenFormModel: ZuweisenFormModel,
    ): MonatsBelegung {

        const belegungen = this.computeBelegungen(inputRows, zuweisenFormModel);

        const anwesenheiten = this.rowsToAnwesenheiten(inputRows);

        const customFields: Map<string, CustomFieldValueNotNamed[]> = new Map();

        inputRows.forEach(value => customFields.set(DvbRestUtil.momentToLocalDateChecked(value.date),
            value.customFieldValues ?? []));

        const result = new MonatsBelegung(
            null,
            checkPresent(zuweisenFormModel.belegungsZustand),
            startOfMonth,
            endOfMonth,
            belegungen,
            anwesenheiten,
            null,
            null,
            null,
            customFields,
        );

        result.standardKontingentId = zuweisenFormModel.standardPlatzTypen.kontingentId;
        result.standardVertraglichesPensum = zuweisenFormModel.standardVertraglichesPensum;

        return result;

    }

    public computeBelegungen(inputRows: MonatsBelegungInputRow[], zuweisenFormModel: ZuweisenFormModel): Belegung[] {

        const inputRowsByIsoWeek = inputRows.reduce(
            (entryMap, row) => entryMap.set(row.date.isoWeek(), [...entryMap.get(row.date.isoWeek()) ?? [], row]),
            new Map<number, MonatsBelegungInputRow[]>(),
        );

        const belegungen = Array.from(inputRowsByIsoWeek.values(), rows => {
            const gruppenBelegungen = this.createGruppenBelegungen(rows, zuweisenFormModel);

            return MonatsBelegungService.createBelegung(
                rows[0].date,
                rows[rows.length - 1].date,
                zuweisenFormModel,
                gruppenBelegungen,
            );
        });

        if (belegungen.every(belegung => belegung.gruppenBelegungen.length === 0)) {
            const wholeMonthBelegung = MonatsBelegungService.createBelegung(
                inputRows[0].date,
                inputRows[inputRows.length - 1].date,
                zuweisenFormModel,
            );

            return [wholeMonthBelegung];
        }

        return belegungen;
    }

    // noinspection JSMethodCanBeStatic
    /**
     * Maps the complex monatsBelegung to a simple Belegung on which the gueltigkeit can be checked.
     */
    public createGueltigkeitCheckBelegung(monatsBelegung: MonatsBelegung): Belegung {
        const checkMonatsBelegung = new Belegung();
        checkMonatsBelegung.belegungsZustand = BelegungsZustand.BELEGT;
        checkMonatsBelegung.gueltigAb = monatsBelegung.gueltigAb;
        checkMonatsBelegung.gueltigBis = monatsBelegung.gueltigBis;

        // Produce gruppen belegungen distinct by gruppeId
        const gruppenBelegungen = monatsBelegung.belegungen.flatMap(belegung => belegung.gruppenBelegungen);

        checkMonatsBelegung.gruppenBelegungen = DvbUtil.uniqueArray(gruppenBelegungen, gb => gb.gruppeId);

        return checkMonatsBelegung;
    }

    private rowsToAnwesenheiten(inputRows: MonatsBelegungInputRow[]): AnwesenheitsZeit[] {
        return inputRows.reduce((previous: AnwesenheitsZeit[], row) => {
            const anwesenheiten = Array.from(row.inputsByZeitraumId.values())
                .filter(zeitInput => zeitInput.showInput)
                .map(zeitInput => zeitInput.anwesenheit)
                .filter(anwesenheit => anwesenheit.von && anwesenheit.bis);

            return previous.concat(anwesenheiten);
        }, []);
    }

    private createGruppenBelegungen(
        inputRows: MonatsBelegungInputRow[],
        zuweisenFormModel: ZuweisenFormModel,
    ): GruppenBelegung[] {
        return inputRows.reduce((totalGruppenBelegungen: GruppenBelegung[], row) => {
            this.createGruppenBelegungenForRow(row).forEach(gruppenBelegung => {
                const existing = totalGruppenBelegungen.find(gb => gb.gruppeId === gruppenBelegung.gruppeId);

                if (existing) {
                    // merge
                    existing.plaetze = existing.plaetze.concat(gruppenBelegung.plaetze);

                    return;
                }

                // add
                const vertraglichePensen = zuweisenFormModel.vertraglichePensen[gruppenBelegung.gruppeId!];
                if (Array.isArray(vertraglichePensen)) {
                    gruppenBelegung.vertraglichePensen = vertraglichePensen.map(pensum =>
                        VertraglichesPensum.from(pensum));
                }
                totalGruppenBelegungen.push(gruppenBelegung);

            });

            return totalGruppenBelegungen;
        }, []);
    }

    private createGruppenBelegungenForRow(row: MonatsBelegungInputRow): GruppenBelegung[] {
        const zeitInputs = Array.from(row.inputsByZeitraumId.values());

        const invalidInput = zeitInputs.find(zeitInput => !zeitInput.hasValidZeit());
        if (invalidInput) {
            throw DvbError.validationError(
                'KIND.MONATSBELEGUNG.ERROR_INVALID_TIMES',
                {
                    date: invalidInput.anwesenheit.datum!.format('DD.MM.YYYY'),
                    name: invalidInput.anwesenheit.betreuungsZeitraum!.name,
                });
        }

        const visibleInputs = zeitInputs.filter(zeitInput => zeitInput.showInput);

        const inputsByGruppeId = visibleInputs.reduce(
            (entryMap, zi) => {
                const gruppeId = checkPresent(zi.selectedGruppe.id);

                return entryMap.set(gruppeId, [...entryMap.get(gruppeId) ?? [], zi]);
            },
            new Map<string, MonatsBelegungZeitInput[]>(),
        );

        return Array.from(inputsByGruppeId.values(), inputs => this.createGruppenBelegung(inputs, row));
    }

    private createGruppenBelegung(
        zeitInputs: MonatsBelegungZeitInput[],
        row: MonatsBelegungInputRow,
    ): GruppenBelegung {

        if (zeitInputs.length === 0) {
            return new GruppenBelegung();
        }

        const fraktion = zeitInputs[0].selectedGruppe;

        const zeitenByKontingentId = zeitInputs
            .filter(zeitInput => zeitInput.anwesenheit.von && zeitInput.anwesenheit.bis)
            .reduce((entryMap, zeitInput) => {
                    const kontingentId = zeitInput.platzTyp.kontingentId;

                    return entryMap.set(kontingentId, [...entryMap.get(kontingentId) ?? [], zeitInput.anwesenheit]);
                },
                new Map<string | null, AnwesenheitsZeit[]>(),
            );

        const einheiten = this.getBelegungsEinheiten(checkPresent(fraktion.wochenplan), row.dayOfWeek);
        const plaetze = this.getPlaetze(zeitenByKontingentId, einheiten, row);

        return new GruppenBelegung(null, plaetze, fraktion, fraktion.id);
    }

    private getPlaetze(
        zeitenByKontingentId: Map<string | null, AnwesenheitsZeit[]>,
        einheiten: BelegungsEinheit[],
        row: MonatsBelegungInputRow,
    ): Platz[] {

        return Array.from(zeitenByKontingentId, ([kontingentId, anwesenheiten]) => {
            const betreuungsZeitraumIds = anwesenheiten
                .map(anwesenheit => checkPresent(anwesenheit.betreuungsZeitraumId));

            const belegungsEinheit = ZeitraumUtil.findBelegungsEinheit(betreuungsZeitraumIds, einheiten);

            if (!belegungsEinheit) {
                const args = {wochentag: row.date.format('DD.MM.YYYY')};
                throw DvbError.validationError('ERRORS.ERR_INVALID_BELEGUNGSEINHEIT', args);
            }

            return MonatsBelegungService.createPlatz(row.dayOfWeek, checkPresent(belegungsEinheit.id), kontingentId);
        });
    }

    private getBelegungsEinheiten(wochenplan: Wochenplan, dayOfWeek: DayOfWeek): BelegungsEinheit[] {
        const find = wochenplan.tagesplaene.find(tagesplan => tagesplan.wochentag === dayOfWeek);

        return find ? find.belegungsEinheiten : [];
    }

    private shortenBelegung(belegung: Belegung, shortenTo: moment.Moment): void {
        belegung.gueltigBis = moment(shortenTo);

        const startOfWeek = DvbDateUtil.startOfWeek(moment(shortenTo));

        belegung.gruppenBelegungen.forEach(gruppenBelegung => {
            gruppenBelegung.plaetze = gruppenBelegung.plaetze
                .filter(platz => {
                    const dayOfWeekMoment = DvbDateUtil
                        .getDayOfWeekMoment(checkPresent(platz.wochentag), moment(startOfWeek));

                    return checkPresent(dayOfWeekMoment).isSameOrBefore(shortenTo);
                });
        });
        belegung.gruppenBelegungen = belegung.gruppenBelegungen
            .filter(gruppenBelegung => gruppenBelegung.plaetze.length > 0);
    }

    private doInitInputRows(
        daysInMonth: number,
        startOfMonth: moment.Moment,
        defaultGruppe: KinderOrtFraktion,
        gruppen: KinderOrtFraktion[],
        customFields: AnwesenheitCustomField[],
        maxDailyHours: Map<KinderOrtId, number>,
        schliesstagDateRangesByKinderOrtId: Map<KinderOrtId, SchliesstagDateRange[]>,
        allowedEditVon: moment.Moment | null = null,
        allowedEditBis: moment.Moment | null = null,
    ): MonatsBelegungInputRow[] {
        const inputRows = [];
        for (let day = 1; day <= daysInMonth; day++) {
            const dateOfMonth = moment(startOfMonth).set('date', day);

            const emptyCustomFieldValues = customFields.filter(field => field.tagesbasiert)
                .map(field => new CustomFieldValueNotNamed(null, field));

            const row = new MonatsBelegungInputRow(
                this.fraktionService,
                new BewerbungStrategy(),
                maxDailyHours,
                dateOfMonth,
                undefined,
                emptyCustomFieldValues);

            row.initBetreuungsZeitraeume(
                gruppen,
                defaultGruppe,
                customFields.filter(field => !field.tagesbasiert),
                schliesstagDateRangesByKinderOrtId,
                allowedEditVon,
                allowedEditBis);

            inputRows.push(row);
        }

        return inputRows;
    }
}
