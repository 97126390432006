/*
 * Copyright © 2018 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import {Injectable} from '@angular/core';
import type {UserSettingsKey, UserSettingsTypeMap} from './userSettingsStore';

@Injectable({
    providedIn: 'root',
})
export class SessionCache {

    private cache: { [index: string]: any } = {};

    public put<T extends UserSettingsKey>(key: T, value?: UserSettingsTypeMap[T]): void {
        this.cache[key] = value;
    }

    public get<T extends UserSettingsKey>(key: T): UserSettingsTypeMap[T] | undefined {
        return this.cache[key];
    }

    public reset(): void {
        this.cache = {};
    }

    public clear(keys: string[]): void {
        keys.forEach(k => {
            delete this.cache[k];
        });
    }
}
