/*
 * Copyright © 2022 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {SearchResultType} from '@dv/shared/code';

type SearchResultRoutes = Exclude<SearchResultType, ''>;

export type EntitySearchStateParams = {
    to: string | ((subRoute?: string) => string);
    idParam: string;
};

export const SEARCH_RESULT_ROUTES: { [k in SearchResultRoutes]: EntitySearchStateParams } = {
    ALL: {to: 'base.global', idParam: 'searchText'},
    ANGESTELLTE: {to: 'base.personal.angestellte.main.profil', idParam: 'id'},
    BENUTZER: {to: 'base.benutzer.profil', idParam: 'id'},
    BETREUUNGS_PERSON: {to: 'base.fraktion.betreuungs_person.profil', idParam: 'id'},
    BETREUUNGSFAKTOR_REGEL: {to: 'base.betreuungsfaktorregel', idParam: 'id'},
    DOKUMENT_LAYOUT: {to: 'base.dokumentlayout', idParam: 'id'},
    FIRMA: {to: 'base.firma.profil', idParam: 'id'},
    FIRMENKONTINGENT: {to: 'base.firmenkontingent', idParam: 'id'},
    GRUPPE: {to: 'base.fraktion.gruppe.profil', idParam: 'id'},
    KIND: {to: (subRoute?): string => `base.kind.${subRoute ?? 'profil'}`, idParam: 'kindId'},
    ANMELDUNG_KIND: {to: (subRoute?): string => `base.kind.${subRoute ?? 'profil'}`, idParam: 'kindId'},
    KITA: {to: (subRoute?): string => `base.kinderort.${subRoute ?? 'uebersicht'}`, idParam: 'id'},
    KONTAKTPERSON: {to: 'base.kontaktperson.profil', idParam: 'kontaktpersonId'},
    // there is no navigation to a "mandant with id" state - only go to own mandant
    MANDANT: {to: 'base.organisation.mandant', idParam: ''},
    RECHNUNG: {to: 'base.rechnung.uebersicht', idParam: 'rechnungId'},
    SCHLIESSTAGE: {to: 'base.schliesstage', idParam: 'id'},
    TAGES_ELTERN: {to: (subRoute?): string => `base.kinderort.${subRoute ?? 'uebersicht'}`, idParam: 'id'},
    TARIF: {to: 'base.tarif', idParam: 'id'},
};
