/*
 * Copyright © 2018 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {IAppConfigService} from '@dv/kitadmin/models';
import {AppConfig} from '@dv/kitadmin/models';
import type {AppConfigKey} from '@dv/shared/backend/model/app-config-key';
import {checkPresent, DvbUtil} from '@dv/shared/code';
import angular from 'angular';
import {CONFIG} from '../../../../config';
import {DvbRestUtilAngularJS} from './dvbRestUtilAngularJS';

export class AppConfigService implements IAppConfigService {
    public static $inject: readonly string[] = ['$http', '$q'];
    private static baseUrl: string = `${CONFIG.restBackend}/api/v1`;

    private config: AppConfig | null = null;
    private deferred: angular.IDeferred<AppConfig> | null = null;

    public constructor(
        private readonly $http: angular.IHttpService,
        private readonly $q: angular.IQService,
    ) {
    }

    public loadConfig(): angular.IDeferred<AppConfig> {
        const url = `${AppConfigService.baseUrl}/anonymous/config`;
        const deferred = this.$q.defer<AppConfig>();

        DvbRestUtilAngularJS.getModelByUrlAndParams(url, AppConfig).then(configParam => {
            this.config = checkPresent(configParam);

            return this.config;
        }).then(() => {
            // resolve the deferred promise so all incoming requests for the config are resolved
            deferred.resolve(this.config!);
        }).catch(reason => {
            // reject it with whatever readon
            deferred.reject(reason);
        });

        return deferred;
    }

    public getConfig(): angular.IPromise<AppConfig> {
        if (this.deferred === null) {
            this.deferred = this.loadConfig();
        }

        return this.deferred.promise.then(() => angular.copy(this.config!));
    }

    public saveConfig(configParam: AppConfig): angular.IPromise<AppConfig> {
        DvbRestUtilAngularJS.clearHttpCache();

        const url = `${AppConfigService.baseUrl}/appconfig`;

        return this.$http.post(url, configParam.toRestObject()).then(() => this.loadConfig().promise);
    }

    public getValue(key: AppConfigKey): angular.IPromise<string | null> {
        return this.getConfig().then(config => {
            const found = DvbUtil.findFirst(config.entries, entry => !!entry && entry.key === key);

            return found ? found.value : null;
        });
    }
}
